import * as actionTypes from "../actions/actionTypes";

const inititalState = {
    error: null,
    fieldErrors: {},
    success: null,
    note: null,
    redirect: null,
    loading: false,
    breadcrump: null,
    language: null,
    overRideServiceMode: false,
    version: "1.5.8",
    newestVersion: null
};

const startLoading = (state, action) => {
    // error: null,
    return { ...state, loading: true };
};

const stopLoading = (state, action) => {
    return { ...state, loading: false };
};

const setError = (state, action) => {
    const newState = {
        error: state.error,
        fieldErrors: { ...state.fieldErrors }
    };
    if (action.clearErrors) {
        newState.error = null;
        newState.fieldErrors = {};
    }
    if (action.error) {
        newState.error = action.error;
    }
    if (action.fieldErrors) {
        newState.fieldErrors = { ...newState.fieldErrors, ...action.fieldErrors };
    }
    return { ...state, ...newState };
};

const clearFieldError = (state, action) => {
    const fieldErrors = state.fieldErrors;
    if (fieldErrors[action.field] !== undefined) {
        delete fieldErrors[action.field];
    }
    return { ...state, fieldErrors };
};

const setSuccess = (state, action) => {
    return { ...state, success: action.success };
};

const clearSuccess = (state, action) => {
    return { ...state, success: null, prepareSuccess: null };
};

const clearError = (state, action) => {
    return { ...state, error: null, fieldErrors: {} };
};

const setNote = (state, action) => {
    return { ...state, note: action.note };
};

const clearNote = (state, action) => {
    return { ...state, note: null };
};

const setRedirect = (state, action) => {
    return { ...state, redirect: action.redirect };
};

const setBreadcrump = (state, action) => {
    return { ...state, breadcrump: action.breadcrump };
};

const setLanguage = (state, action) => {
    return { ...state, language: action.language };
};

const overrideServiceMode = (state, action) => {
    return { ...state, overRideServiceMode: true };
};

const storeVersion = (state, action) => {
    return { ...state, newestVersion: action.version };
};

const reducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.APP_START_LOADING:
            return startLoading(state, action);
        case actionTypes.APP_STOP_LOADING:
            return stopLoading(state, action);
        case actionTypes.APP_SET_SUCCESS:
            return setSuccess(state, action);
        case actionTypes.APP_SET_ERROR:
            return setError(state, action);
        case actionTypes.APP_CLEAR_FIELD_ERROR:
            return clearFieldError(state, action);
        case actionTypes.APP_SET_NOTE:
            return setNote(state, action);
        case actionTypes.APP_CLEAR_SUCCESS:
            return clearSuccess(state, action);
        case actionTypes.APP_CLEAR_ERROR:
            return clearError(state, action);
        case actionTypes.APP_CLEAR_NOTE:
            return clearNote(state, action);
        case actionTypes.APP_SET_REDIRECT:
            return setRedirect(state, action);
        case actionTypes.APP_SET_BREADCRUMP:
            return setBreadcrump(state, action);
        case actionTypes.APP_SET_LANGUAGE:
            return setLanguage(state, action);
        case actionTypes.APP_OVERRIDE_SERVICEMODE:
            return overrideServiceMode(state, action);
        case actionTypes.APP_STORE_VERSION:
            return storeVersion(state, action);

        default:
            return state;
    }
};

export default reducer;
