import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { defineMessages, useIntl } from "react-intl";

import { ReactComponent as Icon } from "../assets/images/note.svg";

import { appClearNote } from "../store/actions/index";

const Note = ({ marginTop }) => {
	const intl = useIntl();
	const messages = defineMessages({
		createAccountPleaseInputAddress: { id: "createAccount.pleaseInputAddress", defaultMessage: "Bitte gib uns deine Adresse bekannt:" },
		createAccountPleaseCheckAddress: { id: "createAccount.pleaseCheckAddress", defaultMessage: "Bitte überprüfe und vervollständige deine Adresse und definiere ein Passwort für dein Konto:" },
		createAccountPleaseDefinePasswordForNewAccount: { id: "createAccount.createAccountPleaseDefinePasswordForNewAccount", defaultMessage: "Bitte definiere ein Passwort für dein neues Konto:" },
		chooseAbosToChangePaymentType: { id: "profil.chooseAbosToChangePaymentType", defaultMessage: "Bitte wähle das Fernbegleitungsabo, bei welchem du die Zahlungsart ändern möchtest:" }
	});

	const note = useSelector((state) => state.app.note);
	let message = "";
	const dispatch = useDispatch();
	const clearNote = useCallback(() => dispatch(appClearNote()), [dispatch]);

	if (note && note in messages) {
		message = intl.formatMessage(messages[note]);
	}

	useEffect(() => {
		return () => {
			if (message) {
				clearNote();
			}
		};
	}, [message, clearNote]);

	return message ? (
		<div className={`message note${marginTop ? " spaceBefore" : ""}`}>
			<div>
				<div>
					<Icon />
				</div>
			</div>
			<div>{message}</div>
		</div>
	) : null;
};

export default React.memo(Note);
