import React from "react";

const HamburgerMenu = ({ toggleMenu }) => {
	return (
		<div id="menuIcon" onClick={toggleMenu}>
			<div></div>
			<div></div>
		</div>
	);
};

export default HamburgerMenu;
