import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getRoute } from "../../shared/routes";

import MenuPoint from "../Menu/MenuPoint/MenuPoint";
import CookieConsentComponent from "../../shared/CookieConsentComponent";

const Footer = ({ isAuthenticated }) => {
    const language = useSelector((state) => state.app.language);
    const version = useSelector((state) => state.app.version);

    return (
        <ul className="footer">
            {isAuthenticated && (
                <MenuPoint to={getRoute("support", language)} exactPath>
                    <FormattedMessage id="menu.support" defaultMessage="Support" />
                </MenuPoint>
            )}
            <MenuPoint to={getRoute("agb", language)} exactPath>
                <FormattedMessage id="menu.agb" defaultMessage="AGB und Widerruf" />
            </MenuPoint>

            <MenuPoint to={getRoute("privacyStatement", language)} exactPath>
                <FormattedMessage id="menu.datenschutz" defaultMessage="Datenschutz" />
            </MenuPoint>
            <li>
                <CookieConsentComponent />
            </li>
            {isAuthenticated && (
                <MenuPoint to={getRoute("deletionConcept", language)} exactPath>
                    <FormattedMessage id="menu.loeschkonzept" defaultMessage="Löschkonzept" />
                </MenuPoint>
            )}
            <MenuPoint to={getRoute("imprint", language)} exactPath>
                <FormattedMessage id="menu.impressum" defaultMessage="Impressum" />
            </MenuPoint>
            {language === "de" && (
                <li>
                    <a href="https://rico-brunner.com/de/fernbehandlung/fernbehandlung-kundigen">
                        <FormattedMessage id="menu.terminate" defaultMessage="VERTRAG HIER KÜNDIGEN" />
                    </a>
                </li>
            )}
            <li className="version">V {version}</li>
        </ul>
    );
};

export default Footer;
