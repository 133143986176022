import * as actionTypes from "./actionTypes";
import axios from "../../rbAxios";
import { appStopLoading, appStartLoading, appSetSuccess, appSetError, appSetRedirect, appSetLanguage } from "./app";
import { updateTokens, startSessionChecker, setSessionInterval, logout, setAutoSignupTried, saveCountryData } from "./auth";

import timeDiff from "../../shared/timeDiff";

export const saveData = (data) => {
	return {
		type: actionTypes.USER_GET_DATA,
		data: data
	};
};

export const userClearData = () => {
	return {
		type: actionTypes.USER_CLEAR_DATA
	};
};

const saveCountry = (country) => {
	return {
		type: actionTypes.USER_SAVE_COUNTRY,
		country: country
	};
};

export const userGetData = () => {
	return (dispatch) => {
		const url = "/api/user";

		axios
			.get(url)
			.then((response) => {
				dispatch(saveData(response.data));
				dispatch(saveCountryData(response.data.countryData));
				dispatch(appStopLoading());
				dispatch(appSetLanguage(response.data.jsonData.fieldData.Sprache.toLowerCase()));
				dispatch(saveCountry(response.data.jsonData.fieldData.Land));
				dispatch(setAutoSignupTried());
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				} else {
					timeDiff.timeDiff = new Date().getTime() - response.data.time;
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const createCustomer = (uniqueKey, addressData, UUIDToBeTreated, redirect) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const data = {
			addressData: addressData,
			UUIDToBeTreated: UUIDToBeTreated
		};
		axios
			.post("/createCustomer/" + uniqueKey, data)
			.then((response) => {
				dispatch(saveData(response.data));
				const sessionInterval = startSessionChecker(dispatch);
				dispatch(setSessionInterval(sessionInterval));
				dispatch(updateTokens(response.data));
				dispatch(appSetSuccess("createAccountSuccessfull"));
				dispatch(appStopLoading());
			})
			.catch((err) => {
				dispatch(appSetRedirect(redirect));
				dispatch(appSetError("createAccountCompleteRegistrationWithinOneHour"));
				dispatch(appStopLoading());
			});
	};
};

export const importCustomer = (uniqueKey, addressData, fmData, redirect) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const data = {
			addressData: addressData,
			fmData: fmData
		};
		axios
			.post("/importCustomer/" + uniqueKey, data)
			.then((response) => {
				dispatch(saveData(response.data));
				const sessionInterval = startSessionChecker(dispatch);
				dispatch(setSessionInterval(sessionInterval));
				dispatch(updateTokens(response.data));
				dispatch(appSetSuccess("createAccountSuccessfull"));
				dispatch(appStopLoading());
			})
			.catch((err) => {
				dispatch(appSetRedirect(redirect));
				dispatch(appSetError("createAccountCompleteRegistrationWithinOneHour"));
				dispatch(appStopLoading());
			});
	};
};

const prepareSound = (soundURL) => {
	return {
		type: actionTypes.USER_PREPARE_SOUND,
		sound: soundURL
	};
};

export const userPrepareSound = (uuidSound, title) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		axios
			.get("/api/prepareSound/" + uuidSound)
			.then((response) => {
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(prepareSound(response.data.url));
				dispatch(appStopLoading());
			})
			.catch((err) => {
				dispatch(
					appSetError({
						key: "audioXisNotAvailableRightNow",
						values: { title: title }
					})
				);
				dispatch(appStopLoading());
			});
	};
};

export const userStopSound = () => {
	return {
		type: actionTypes.USER_STOP_SOUND
	};
};

export const userOrderShortAnalysis = (UUIDAudio, UUIDKunde, UUIDFernbehandlung, BerichtDatum) => {
	const fmWrite = {
		data: {
			fieldData: {
				UUIDAudio: UUIDAudio,
				UUIDKunde: UUIDKunde,
				UUIDFernbehandlung: UUIDFernbehandlung,
				BerichtDatum: BerichtDatum,
				ModifiedDateTimeKP: 1
			},
			script: "AudioSyncWrite"
		},
		table: "AudioSyncWrite"
	};

	const newShortAnalysis = {
		"_Audio::Berichttyp": "between",
		"_Audio::UUIDAudio": UUIDAudio,
		"_Audio::AudioDatSync": BerichtDatum,
		"_Audio::UUIDFernbehandlung": UUIDFernbehandlung,
		"_Audio::Audioaufnahme": ""
	};
	return {
		type: actionTypes.USER_ADD_SHORT_ANALYSIS,
		fmWrite: fmWrite,
		newShortAnalysis: newShortAnalysis,
		saveToApi: true,
		success: {
			message: {
				message: "shortAnalysisOrderedSuccessfully",
				location: "shortAnalysis"
			}
		}
	};
};

export const userOrderSymptomFocusing = (UUIDKunde, UUIDFernbehandlung, dates, stichwort) => {
	const fmWrite = {
		data: dates.map((entry) => ({
			fieldData: {
				UUIDFernbehandlung: UUIDFernbehandlung,
				UUIDSymptomfokussierung: entry.uuid,
				UUIDKunde: UUIDKunde,
				Datum: entry.date,
				Behandlungstichwort: stichwort,
				ModifiedDateTimeKP: 1
			},
			script: "SymptomfokussierungenSyncWrite"
		})),
		table: "SymptomfokussierungenSyncWrite"
	};

	const newSymptomFocusings = dates.map((entry) => ({
		"_SF::UUIDSymptomfokussierung": entry.uuid,
		"_SF::Datum": entry.date,
		"_SF::UUIDFernbehandlung": UUIDFernbehandlung,
		"_SF::Behandlungstichwort": stichwort
	}));

	return {
		type: actionTypes.USER_ADD_SYMPTOM_FOCUSING,
		newSymptomFocusings: newSymptomFocusings,
		fmWrite: fmWrite,
		saveToApi: true,
		success: {
			message: {
				message: dates.length > 1 ? "symptomFocusingesCreatedSuccessfully" : "symptomFocusingCreatedSuccessfully",
				location: "symptomFocusing"
			}
		}
	};
};

export const userDeleteSymptomFocusing = (UUIDSymptomFocusing, UUIDKunde) => {
	const fmWrite = {
		data: {
			fieldData: {
				UUIDObjekt: UUIDSymptomFocusing,
				UUIDKunde: UUIDKunde,
				Objektart: "SF",
				ModifiedDateTimeKP: 1
			},
			script: "LoeschenSyncWrite"
		},
		table: "LoeschenSyncWrite"
	};

	return {
		type: actionTypes.USER_DELETE_SYMPTOM_FOCUSING,
		deleteUUID: UUIDSymptomFocusing,
		fmWrite: fmWrite,
		saveToApi: true,
		success: {
			message: {
				message: "symptomFocusingDeletedSuccessfully",
				location: "symptomFocusing"
			}
		}
	};
};

export const userCreateToBeTreated = (UUIDKunde, UUIDToBeTreated, firstname, lastname, description, category, defaultPerson = "") => {
	const newToBeTreated = {
		"_ZB::UUIDZuBehandelnder": UUIDToBeTreated,
		"_ZB::Vorname": firstname,
		"_ZB::Name": lastname,
		"_ZB::Bezeichnung": description,
		"_ZB::DefaultZuBehandelnder": defaultPerson,
		"_ZB::Kategorie": category,
		"_ZB::Aktiv": "aktiv"
	};

	const fmWrite = {
		data: {
			fieldData: {
				UUIDKunde: UUIDKunde,
				UUIDZuBehandelnder: UUIDToBeTreated,
				Vorname: firstname,
				Name: lastname,
				Bezeichnung: description,
				Kategorie: category,
				DefaultZuBehandelnder: defaultPerson,
				SyncActionKP: "Neu"
			},
			script: "ZuBehandelndeSyncWrite"
		},
		table: "ZuBehandelndeSyncWrite"
	};
	return {
		type: actionTypes.USER_ADD_TO_BE_TREATED,
		newToBeTreated: newToBeTreated,
		fmWrite: fmWrite,
		saveToApi: true
	};
};

export const userSaveAddress = (addressData) => {
	const fmWrite = {
		data: {
			fieldData: {
				...addressData,
				SyncActionKP: "UpdateKunde",
				ModifiedDateTimeKP: 1
			},
			script: "KundeSyncWrite"
		},
		table: "KundeSyncWrite"
	};

	return {
		type: actionTypes.USER_SAVE_ADDRESS,
		data: addressData,
		fmWrite: fmWrite,
		saveToApi: true,
		success: {
			message: "addressSavedSuccessfully"
		}
	};
};

const changeCurrency = (currency) => {
	return {
		type: actionTypes.USER_CHANGE_CURRENCY,
		currency: currency
	};
};

export const sendDataToServer = (customerData, payload) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const postData = {
			customerData: customerData,
			payload: payload
		};
		axios
			.post("/api/user/" + customerData.data.fieldData.UUIDKunde + "/save", postData)
			.then((response) => {
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				if (response.data.newCurrency) {
					dispatch(changeCurrency(response.data.newCurrency));
				}
				if (payload.success) {
					if (payload.success.redirect) {
						dispatch(appSetRedirect(payload.success.redirect));
					}
					if (payload.success.message) {
						dispatch(appSetSuccess(payload.success.message));
					}
				}
				dispatch(appStopLoading());
			})
			.catch((err) => {
				dispatch(appStopLoading());
			});
	};
};

export const userRemoveToBeTreated = (UUIDKunde, UUIDToBeTreated) => {
	const fmWrite = {
		data: {
			fieldData: {
				UUIDKunde: UUIDKunde,
				UUIDZuBehandelnder: UUIDToBeTreated,
				SyncActionKP: "Inaktiv"
			},
			script: "ZuBehandelndeSyncWrite"
		},
		table: "ZuBehandelndeSyncWrite"
	};
	return {
		type: actionTypes.USER_REMOVE_TO_BE_TREATED,
		fmWrite: fmWrite,
		removeUUID: UUIDToBeTreated,
		saveToApi: true
	};
};

export const userAddTreatmentToCart = (uuidTreatment, toBeTreatedUUID, reason, startDate, saveToApi, redirectTo) => {
	const data = {
		type: actionTypes.USER_ADD_TREATMENT_TO_CART,
		newItem: {
			uuidTreatment: uuidTreatment,
			productCode: "abo",
			toBeTreatedUUID: toBeTreatedUUID,
			reason: reason,
			startDate: startDate
		},
		saveToApi: saveToApi
	};
	if (saveToApi) {
		data.success = {
			redirect: redirectTo
		};
	}
	return data;
};

export const userAddEnergyAnalysisToCart = (toBeTreatedUUID, uuidTreatment, redirectTo) => {
	return {
		type: actionTypes.USER_ADD_ENERGY_ANALYSIS_TO_CART,
		newItem: {
			uuidTreatment: uuidTreatment,
			toBeTreatedUUID: toBeTreatedUUID,
			productCode: "audio"
		},
		saveToApi: true,
		success: {
			redirect: redirectTo
		}
	};
};

export const userRemoveProductFromCart = (index) => {
	return {
		type: actionTypes.USER_REMOVE_PRODUCT_FROM_CART,
		index: index,
		saveToApi: true
	};
};

export const userTerminateTreatment = (UUIDFernbehandlung, date) => {
	const fmWrite = {
		data: {
			fieldData: {
				UUIDFernbehandlung: UUIDFernbehandlung,
				SyncActionKP: "Kuendigung",
				ModifiedDateTimeKP: 1
			},
			script: "FernbehandlungenUpdateWrite"
		},
		table: "FernbehandlungenUpdateWrite"
	};
	return {
		type: actionTypes.USER_TERMINATE_TREATMENT,
		fmWrite: fmWrite,
		terminateUUID: UUIDFernbehandlung,
		saveToApi: true,
		success: {
			message: {
				key: "treatmentTerminatedSuccessfully",
				values: {
					date: date
				}
			}
		}
	};
};

export const userUndoTerminateTreatment = (UUIDFernbehandlung) => {
	const fmWrite = {
		data: {
			fieldData: {
				UUIDFernbehandlung: UUIDFernbehandlung,
				SyncActionKP: "KuendigungWiderrufen",
				ModifiedDateTimeKP: 1
			},
			script: "FernbehandlungenUpdateWrite"
		},
		table: "FernbehandlungenUpdateWrite"
	};
	return {
		type: actionTypes.USER_UNDO_TERMINATE_TREATMENT,
		fmWrite: fmWrite,
		terminateUUID: UUIDFernbehandlung,
		saveToApi: true,
		success: {
			message: {
				key: "undoTerminatedTreatmentSuccessfully"
			}
		}
	};
};

export const userChangeTreatmentReason = (UUIDFernbehandlung, Behandlungsgrund) => {
	const fmWrite = {
		data: {
			fieldData: {
				UUIDFernbehandlung: UUIDFernbehandlung,
				Behandlungsgrund: Behandlungsgrund,
				SyncActionKP: "Behandlungsgrund",
				ModifiedDateTimeKP: 1
			},
			script: "FernbehandlungenUpdateWrite"
		},
		table: "FernbehandlungenUpdateWrite"
	};
	return {
		type: actionTypes.USER_CHANGE_TREATMENT_REASON,
		fmWrite: fmWrite,
		uuidTreatment: UUIDFernbehandlung,
		reason: Behandlungsgrund,
		saveToApi: true
	};
};

export const userGetInvoice = (uuid, nr, link, filename) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		axios
			.get("/api/getInvoice/" + uuid + "/" + nr, { responseType: "blob" })
			.then((response) => {
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					// IE variant
					window.navigator.msSaveOrOpenBlob(new Blob([response.data]));
				} else {
					const href = window.URL.createObjectURL(new Blob([response.data]));
					link.current.download = filename;
					link.current.href = href;
					link.current.click();
				}
				dispatch(appStopLoading());
			})
			.catch((err) => {
				dispatch(
					appSetError({
						key: "invoiceXisNotAvailableRightNow",
						values: { nr: nr }
					})
				);
				dispatch(appStopLoading());
			});
	};
};

export const userChangePassword = (uuidCustomer, currentPassword, newPassword) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const postData = {
			currentPassword: currentPassword,
			newPassword: newPassword
		};
		axios
			.patch("/api/user/" + uuidCustomer + "/changePassword", postData)
			.then((response) => {
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(appSetSuccess("passwordChangedSuccessfully"));
				dispatch(appStopLoading());
			})
			.catch((err) => {
				if (err.response.status === 403) {
					dispatch(appSetError(err.response.data.error, err.response.data.fieldErrors, true));
				}
				dispatch(appStopLoading());
			});
	};
};

export const userMoneyBackWarranty = (UUIDKunde, UUIDToBeTreated, affectedTreatments) => {
	const fmWrite = {
		data: {
			fieldData: {
				UUIDKunde: UUIDKunde,
				UUIDZuBehandelnder: UUIDToBeTreated,
				SyncActionKP: "GeldZurueckGarantie",
				GarantieBeansprucht: 1
			},
			script: "ZuBehandelndeSyncWrite"
		},
		table: "ZuBehandelndeSyncWrite"
	};
	return {
		type: actionTypes.USER_MONEY_BACK_WARRANTY,
		UUIDToBeTreated: UUIDToBeTreated,
		affectedTreatments: affectedTreatments,
		fmWrite: fmWrite,
		saveToApi: true,
		success: {
			message: "moneyBackWarrantySuccessfully"
		}
	};
};

const redirectToPay = (url) => {
	return {
		type: actionTypes.USER_SET_REDIRECT_TO_PAY,
		redirectToPay: url
	};
};

export const prepareDataTransPayment = (uuidCustomer, amount, currency, paymentMethod) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const postData = {
			amount: amount,
			currency: currency,
			paymentMethod: paymentMethod
		};
		axios
			.post("/api/user/" + uuidCustomer + "/prepareDataTransPayment", postData)
			.then((response) => {
				console.log(response.data);
				dispatch(redirectToPay(response.data.url));
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(appStopLoading());
			})
			.catch((err) => {
				if (err.response.status === 403) {
					dispatch(appSetError(err.response.data.error, err.response.data.fieldErrors, true));
				}
				dispatch(appStopLoading());
			});
	};
};

const storeStripeId = (stripeId) => {
	return {
		type: actionTypes.USER_STORE_STRIPE_ID,
		stripeId: stripeId
	};
};

export const userOrder = (uuidCustomer, order, successURL, errorURL) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const postData = {
			order: order,
			successURL: successURL,
			errorURL: errorURL
		};
		axios
			.post("/api/user/" + uuidCustomer + "/order", postData)
			.then((response) => {
				if (order.paymentMethod === "bill") {
					dispatch(saveData(response.data));
					dispatch(appSetRedirect("/"));
					dispatch(appSetSuccess("orderSubmittedSuccessfully"));
				} else if (order.paymentMethod === "CARD") {
					dispatch(storeStripeId(response.data.id));
				} else {
					dispatch(redirectToPay(response.data.url));
				}
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(appStopLoading());
			})
			.catch((err) => {
				if (err.response.status === 403) {
					dispatch(appSetError(err.response.data.error, err.response.data.fieldErrors, true));
				}
				dispatch(appStopLoading());
			});
	};
};

export const userChangeEMail = (uuidCustomer, eMail, mailSubject, mailTextBeforeLink, mailTextAfterLink, link, redirect) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const postData = {
			eMail: eMail,
			mailSubject: mailSubject,
			mailTextBeforeLink: mailTextBeforeLink,
			mailTextAfterLink: mailTextAfterLink,
			link: link
		};
		axios
			.patch("/api/user/" + uuidCustomer + "/changeEMail", postData)
			.then((response) => {
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(appSetRedirect(redirect));
				dispatch(appSetSuccess("eMailChangeMailSent"));
				dispatch(appStopLoading());
			})
			.catch((err) => {
				switch (err.response.status) {
					case 403:
						dispatch(appSetError(err.response.data.error, err.response.data.fieldErrors, true));
						break;

					case 406:
						dispatch(appSetError(err.response.data.error, err.response.data.fieldErrors, true));
						break;

					case 409:
						dispatch(appSetError(err.response.data.error, err.response.data.fieldErrors, true));
						break;

					default:
				}
				dispatch(appStopLoading());
			});
	};
};

const updateEMailAddress = (eMail) => {
	return {
		type: actionTypes.USER_UPDATE_EMAIL,
		eMail: eMail
	};
};

export const userConfirmEMailChange = (key, redirectOnSuccess, redirectOnError) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		axios
			.patch("/confirmEMailChange/" + key, {})
			.then((response) => {
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(updateEMailAddress(response.data.eMail));
				dispatch(appSetRedirect(redirectOnSuccess));
				dispatch(appSetSuccess("eMailChangeSuccessfully"));
				dispatch(appStopLoading());
			})
			.catch((err) => {
				dispatch(appSetRedirect(redirectOnError));
				dispatch(appSetError("urlNotValidAnymore"));
				dispatch(appStopLoading());
			});
	};
};

export const userSendSupportRequest = (uuidCustomer, data, redirect) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		axios
			.post("/api/user/" + uuidCustomer + "/supportRequest", data)
			.then((response) => {
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(appSetRedirect(redirect));
				dispatch(appSetSuccess("supportRequestSuccessfullySent"));
				dispatch(appStopLoading());
			})
			.catch((err) => {
				dispatch(appStopLoading());
			});
	};
};

export const _deleteAccount = (uuidCustomer) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		axios
			.delete("/api/user/" + uuidCustomer)
			.then((response) => {
				dispatch(appStopLoading());
				dispatch(logout());
			})
			.catch((err) => {
				dispatch(appStopLoading());
				if (err.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const _storePaymentServiceProviderState = (state) => {
	return {
		type: actionTypes.USER_STORE_PAYMENT_SERVICE_PROVIDER_STATE,
		state: state
	};
};

export const _getPaymentServiceProviderState = (uuidCustomer, redirect = null) => {
	return (dispatch) => {
		axios
			.get("/api/user/" + uuidCustomer + "/paymentServiceProviderState")
			.then((response) => {
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(_storePaymentServiceProviderState(response.data.state));
				switch (response.data.state) {
					case "success":
						dispatch(saveData(response.data.customerData));
						dispatch(appStopLoading());
						dispatch(appSetRedirect(redirect));
						dispatch(appSetSuccess("paymentMethodSuccessfullyChangedPaymentDone"));
						break;

					case "error":
						dispatch(appStopLoading());
						dispatch(appSetRedirect(redirect));
						dispatch(appSetError("paymentMethodSuccessfullyChangedPaymentFailed"));
						break;

					case "idle":
						dispatch(saveData(response.data.customerData));
						dispatch(appStopLoading());
						if (redirect) {
							dispatch(appSetRedirect(redirect));
							dispatch(appSetSuccess("paymentMethodSuccessfullyChanged"));
						} else {
							dispatch(appSetRedirect("/"));
							dispatch(appSetSuccess("orderAndPaymentSuccessfully"));
						}
						break;

					default:
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

const storePaymentData = (data) => {
	return {
		type: actionTypes.STORE_PAYMENT_DATA,
		paymentData: data
	};
};

export const _clearPaymentData = () => {
	return {
		type: actionTypes.CLEAR_PAYMENT_DATA
	};
};

export const _getPaymentData = (paymentDataIds) => {
	if (paymentDataIds.length > 0) {
		return (dispatch) => {
			dispatch(appStartLoading());
			axios
				.get("/api/getPaymentData?paymentDataIds=" + paymentDataIds.join(","))
				.then((response) => {
					if (response.config.updatedToken) {
						dispatch(updateTokens(response.config.updatedToken));
					}
					if (response.status !== 406) {
						dispatch(storePaymentData(response.data));
						dispatch(appStopLoading());
					}
				})
				.catch((err) => {
					dispatch(appStopLoading());
					if (err.response.status === 401) {
						dispatch(logout());
					}
				});
		};
	} else {
		return (dispatch) => {
			dispatch(storePaymentData([]));
		};
	}
};

export const _setPaymentChangeAbos = (uuidTreatments) => {
	return {
		type: actionTypes.SET_PAYMENT_CHANGE_ABOS,
		uuidTreatments: uuidTreatments
	};
};

export const _changePaymentMethodToBill = (uuidTreatments, uuidCustomer) => {
	const fmWrite = {
		data: uuidTreatments.map((uuidTreatment) => ({
			fieldData: {
				UUIDKunde: uuidCustomer,
				UUIDFernbehandlung: uuidTreatment,
				Zahlungsart: "Rechnung",
				aliasCC: "",
				ModifiedDateTimeKP: 1
			},
			script: "ZahlungsartUpdateWrite"
		})),
		table: "ZahlungsartUpdateWrite"
	};
	return {
		type: actionTypes.CHANGE_PAYMENT_TO_BILL,
		uuidTreatments: uuidTreatments,
		fmWrite: fmWrite,
		saveToApi: true,
		success: {
			redirect: "/konto/zahlungsart_abos",
			message: "paymentMethodSuccessfullyChanged"
		}
	};
};

export const _changePaymentMethod = (uuidTreatments, uuidCustomer, paymentMethod, currency) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const postData = {
			uuidTreatments: uuidTreatments,
			paymentMethod: paymentMethod,
			currency: currency
		};
		axios
			.post("/api/user/" + uuidCustomer + "/changePaymentMethod", postData)
			.then((response) => {
				console.log(response.data);
				if (paymentMethod === "CARD") {
					dispatch(storeStripeId(response.data.id));
				} else {
					dispatch(redirectToPay(response.data.url));
				}
				if (response.config.updatedToken) {
					dispatch(updateTokens(response.config.updatedToken));
				}
				dispatch(appStopLoading());
			})
			.catch((err) => {
				if (err.response.status === 403) {
					dispatch(appSetError(err.response.data.error, err.response.data.fieldErrors, true));
				}
				dispatch(appStopLoading());
			});
	};
};
