import * as actionTypes from "../actions/actionTypes";
import { getDateFromFMDate, toFMDate, today } from "../../shared/utility";

const initialState = {
	data: null,
	sound: null,
	cart: [],
	redirectToPay: null,
	paymentData: null,
	paymentChangeAbos: [],
	stripeId: null,
	presets: null,
	payErrors: [],
	paymentServiceProviderState: "",
	country: ""
};

const saveData = (state, action) => {
	return { ...state, data: action.data.jsonData, cart: action.data.cart ? action.data.cart : [], presets: action.data.presets ? { ...action.data.presets } : null, payErrors: action.data.payErrors ? [...action.data.payErrors] : [] };
};

const saveAddress = (state, action) => {
	return { ...state, data: { ...state.data, fieldData: action.data } };
};

const prepareSound = (state, action) => {
	return { ...state, sound: action.sound };
};

const stopSound = (state, action) => {
	return { ...state, sound: null };
};

const addShortAnalysis = (state, action) => {
	const uuidTreatment = action.newShortAnalysis["_Audio::UUIDFernbehandlung"];
	const treatments = state.data.portalData["_FB"].map((entry) => {
		if (entry["_FB::UUIDFernbehandlung"] === uuidTreatment) {
			entry["_FB::DatNextZwischenbericht"] = "";
		}
		return entry;
	});
	return { ...state, data: { ...state.data, portalData: { ...state.data.portalData, _FB: [...treatments], _Audio: [...state.data.portalData["_Audio"], action.newShortAnalysis] } } };
};

const addSymptomFocusing = (state, action) => {
	return { ...state, data: { ...state.data, portalData: { ...state.data.portalData, _SF: [...state.data.portalData["_SF"], ...action.newSymptomFocusings] } } };
};

const deleteSymptomFocusing = (state, action) => {
	return { ...state, data: { ...state.data, portalData: { ...state.data.portalData, _SF: state.data.portalData["_SF"].filter((sf) => sf["_SF::UUIDSymptomfokussierung"] !== action.deleteUUID) } } };
};

const clearData = (state, action) => {
	return initialState;
};

const createToBeTreated = (state, action) => {
	return { ...state, data: { ...state.data, portalData: { ...state.data.portalData, _ZB: [...state.data.portalData["_ZB"], action.newToBeTreated] } } };
};

const removeToBeTreated = (state, action) => {
	const toBeTreated = state.data.portalData["_ZB"].map((entry) => {
		if (entry["_ZB::UUIDZuBehandelnder"] === action.removeUUID) {
			entry["_ZB::Aktiv"] = "";
		}
		return entry;
	});
	return { ...state, data: { ...state.data, portalData: { ...state.data.portalData, _ZB: toBeTreated } } };
};

const addTreatmentToCart = (state, action) => {
	return { ...state, cart: [...state.cart, action.newItem] };
};

const addEnergyAnalysisToCart = (state, action) => {
	return { ...state, cart: [...state.cart, action.newItem] };
};

const removeProductFromCart = (state, action) => {
	const productToRemove = state.cart[action.index];
	const newCart = state.cart.filter((entry, index) => index !== action.index).filter((entry) => entry.productCode !== "audio" || entry.uuidTreatment !== productToRemove.uuidTreatment);
	return { ...state, cart: [...newCart] };
};

const terminateTreatment = (state, action) => {
	const currentTreatment = state.data.portalData["_FB"].find((entry) => entry["_FB::UUIDFernbehandlung"] === action.terminateUUID);
	if (currentTreatment) {
		const terminateDate = getDateFromFMDate(currentTreatment["_FB::DatumEnde"]);
		const symptomFocusing = state.data.portalData["_SF"].filter((entry) => {
			if (entry["_SF::UUIDFernbehandlung"] === action.terminateUUID) {
				return getDateFromFMDate(entry["_SF::Datum"]) <= terminateDate;
			} else {
				return true;
			}
		});
		return {
			...state,
			data: {
				...state.data,
				portalData: {
					...state.data.portalData,
					_FB: state.data.portalData["_FB"].map((entry) => {
						if (entry["_FB::UUIDFernbehandlung"] === action.terminateUUID) {
							entry["_FB::Gekuendigt"] = "Ja";
						}
						return entry;
					}),
					_SF: symptomFocusing
				}
			}
		};
	} else {
		return state;
	}
};

const undoTerminateTreatment = (state, action) => {
	const currentTreatment = state.data.portalData["_FB"].find((entry) => entry["_FB::UUIDFernbehandlung"] === action.terminateUUID);
	if (currentTreatment) {
		const terminateDate = getDateFromFMDate(currentTreatment["_FB::DatumEnde"]);
		const symptomFocusing = state.data.portalData["_SF"].filter((entry) => {
			if (entry["_SF::UUIDFernbehandlung"] === action.terminateUUID) {
				return getDateFromFMDate(entry["_SF::Datum"]) <= terminateDate;
			} else {
				return true;
			}
		});
		return {
			...state,
			data: {
				...state.data,
				portalData: {
					...state.data.portalData,
					_FB: state.data.portalData["_FB"].map((entry) => {
						if (entry["_FB::UUIDFernbehandlung"] === action.terminateUUID) {
							entry["_FB::Gekuendigt"] = "";
						}
						return entry;
					}),
					_SF: symptomFocusing
				}
			}
		};
	} else {
		return state;
	}
};

const changeTreatmentReason = (state, action) => {
	const treatments = state.data.portalData["_FB"].map((entry) => {
		if (entry["_FB::UUIDFernbehandlung"] === action.uuidTreatment) {
			entry["_FB::Behandlungsgrund"] = action.reason;
		}
		return entry;
	});
	return {
		...state,
		data: {
			...state.data,
			portalData: {
				...state.data.portalData,
				_FB: treatments
			}
		}
	};
};

const redirectToPay = (state, action) => {
	return { ...state, redirectToPay: action.redirectToPay };
};

const clearCart = (state, action) => {
	return { ...state, cart: [] };
};

const updateEMail = (state, action) => {
	return { ...state, data: { ...state.data, fieldData: { ...state.data.fieldData, eMail: action.eMail } } };
};

const changeCurrency = (state, action) => {
	return { ...state, data: { ...state.data, fieldData: { ...state.data.fieldData, Waehrung: action.currency } } };
};

const orderTreatments = (state, action) => {
	return {
		...state,
		cart: [],
		data: {
			...state.data,
			portalData: {
				...state.data.portalData,
				_FB: [...state.data.portalData["_FB"], ...action.newTreatments],
				_Audio: [...state.data.portalData["_Audio"], ...action.newShortAnalysis]
			}
		}
	};
};

const moneyBackWarranty = (state, action) => {
	return {
		...state,
		data: {
			...state.data,
			portalData: {
				...state.data.portalData,
				_FB: state.data.portalData["_FB"].map((entry) => {
					if (action.affectedTreatments.indexOf(entry["_FB::UUIDFernbehandlung"]) > -1) {
						entry["_FB::GeldZurueckGarantieBeansprucht"] = "1";
					}
					return entry;
				}),
				_ZB: state.data.portalData["_ZB"].map((entry) => {
					if (entry["_ZB::UUIDZuBehandelnder"] === action.UUIDToBeTreated) {
						entry["_ZB::GarantieBeanspruchtDat"] = toFMDate(today());
						entry["_ZB::GarantieStatus"] = "In Anspruch genommen";
					}
					return entry;
				})
			}
		}
	};
};

const storePaymentData = (state, action) => {
	return { ...state, paymentData: [...action.paymentData] };
};

const clearPaymentData = (state, action) => {
	return { ...state, paymentData: null };
};

const setPaymentChangeAbos = (state, action) => {
	return { ...state, paymentChangeAbos: [...action.uuidTreatments] };
};

const changePaymentToBill = (state, action) => {
	return {
		...state,
		data: {
			...state.data,
			portalData: {
				...state.data.portalData,
				_FB: state.data.portalData["_FB"].map((entry) => {
					if (action.uuidTreatments.indexOf(entry["_FB::UUIDFernbehandlung"]) > -1) {
						entry["_FB::aliasCC"] = "";
					}
					return entry;
				})
			}
		}
	};
};

const storeStripeId = (state, action) => {
	return { ...state, stripeId: action.stripeId };
};

const storePaymentServiceProviderState = (state, action) => {
	return { ...state, paymentServiceProviderState: action.state };
};

const saveCountry = (state, action) => {
	return { ...state, country: action.country };
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.USER_GET_DATA:
			return saveData(state, action);
		case actionTypes.USER_SAVE_ADDRESS:
			return saveAddress(state, action);
		case actionTypes.USER_PREPARE_SOUND:
			return prepareSound(state, action);
		case actionTypes.USER_STOP_SOUND:
			return stopSound(state, action);
		case actionTypes.USER_ADD_SHORT_ANALYSIS:
			return addShortAnalysis(state, action);
		case actionTypes.USER_ADD_SYMPTOM_FOCUSING:
			return addSymptomFocusing(state, action);
		case actionTypes.USER_DELETE_SYMPTOM_FOCUSING:
			return deleteSymptomFocusing(state, action);
		case actionTypes.USER_CLEAR_DATA:
			return clearData(state, action);
		case actionTypes.USER_ADD_TO_BE_TREATED:
			return createToBeTreated(state, action);
		case actionTypes.USER_REMOVE_TO_BE_TREATED:
			return removeToBeTreated(state, action);
		case actionTypes.USER_ADD_TREATMENT_TO_CART:
			return addTreatmentToCart(state, action);
		case actionTypes.USER_ADD_ENERGY_ANALYSIS_TO_CART:
			return addEnergyAnalysisToCart(state, action);
		case actionTypes.USER_REMOVE_PRODUCT_FROM_CART:
			return removeProductFromCart(state, action);
		case actionTypes.USER_TERMINATE_TREATMENT:
			return terminateTreatment(state, action);
		case actionTypes.USER_UNDO_TERMINATE_TREATMENT:
			return undoTerminateTreatment(state, action);
		case actionTypes.USER_CHANGE_TREATMENT_REASON:
			return changeTreatmentReason(state, action);
		case actionTypes.USER_SET_REDIRECT_TO_PAY:
			return redirectToPay(state, action);
		case actionTypes.USER_CLEAR_CART:
			return clearCart(state, action);
		case actionTypes.USER_ORDER_TREATMENTS:
			return orderTreatments(state, action);
		case actionTypes.USER_MONEY_BACK_WARRANTY:
			return moneyBackWarranty(state, action);
		case actionTypes.USER_UPDATE_EMAIL:
			return updateEMail(state, action);
		case actionTypes.STORE_PAYMENT_DATA:
			return storePaymentData(state, action);
		case actionTypes.CLEAR_PAYMENT_DATA:
			return clearPaymentData(state, action);
		case actionTypes.SET_PAYMENT_CHANGE_ABOS:
			return setPaymentChangeAbos(state, action);
		case actionTypes.CHANGE_PAYMENT_TO_BILL:
			return changePaymentToBill(state, action);
		case actionTypes.USER_CHANGE_CURRENCY:
			return changeCurrency(state, action);
		case actionTypes.USER_STORE_STRIPE_ID:
			return storeStripeId(state, action);
		case actionTypes.USER_STORE_PAYMENT_SERVICE_PROVIDER_STATE:
			return storePaymentServiceProviderState(state, action);
		case actionTypes.USER_SAVE_COUNTRY:
			return saveCountry(state, action);

		default:
			return state;
	}
};

export default reducer;
