import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { overrideServiceMode } from "../../store/actions";

const ServiceMode = () => {
	const [showButton, setShowButton] = useState(false);
	const dispatch = useDispatch();
	const overrideServiceModeNow = () => dispatch(overrideServiceMode());

	useEffect(() => {
		window.addEventListener("keydown", (event) => {
			//console.log(event.altKey);
			if (event.altKey && event.ctrlKey) {
				setShowButton(true);
			}
		});
	}, [setShowButton]);

	return (
		<div className="serviceMode">
			<h1>
				<FormattedMessage id="service.title" defaultMessage="Wartungsmodus" />
			</h1>
			<p>
				<FormattedMessage
					id="service.description"
					defaultMessage="Die Rico Brunner App befindet sich gerade im Wartungsmodus und ist deshalb nicht erreichbar.
Wir sind in Kürze wieder online. Bitte besuche uns später noch einmal.{br}{br}
Bei Fragen kannst du uns gerne eine Mail schreiben: <a>info@rico-brunner.com</a>.{br}{br}
Herzlichen Dank für dein Verständnis und bis bald...{br}{br}
Liebe Grüsse"
					values={{
						br: <br />,
						a: (...chunks) => <a href="mailto:info@rico-brunner.com">{chunks}</a>
					}}
				/>
			</p>
			{showButton && (
				<button type="button" onClick={overrideServiceModeNow}>
					Wartungsmodus übergehen
				</button>
			)}
		</div>
	);
};

export default ServiceMode;
