import React from "react";
import ReactDOM from "react-dom";

import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import config from "react-global-configuration";

import { sendDataToServer } from "./store/actions/index";

import "./index.css";
import "./fonts/proximanova-bold-webfont.woff";
import "./fonts/proximanova-bold-webfont.woff2";
import "./fonts/proximanova-light-webfont.woff";
import "./fonts/proximanova-light-webfont.woff2";
import "./fonts/proximanova-semibold-webfont.woff";
import "./fonts/proximanova-semibold-webfont.woff2";

import appReducer from "./store/reducer/app";
import authReducer from "./store/reducer/auth";
import userReducer from "./store/reducer/user";

import Language from "./Language";

import * as serviceWorker from "./serviceWorker";

const composeEnhancers = (process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
	app: appReducer,
	auth: authReducer,
	user: userReducer
});

const logger = (store) => (next) => (action) => {
	let result = next(action);
	if (action.saveToApi) {
		const data = store.getState();
		store.dispatch(sendDataToServer(data.user, action));
	}
	return result;
};

if (!Intl.PluralRules) {
	require("@formatjs/intl-pluralrules/polyfill");
	require("@formatjs/intl-pluralrules/locale-data/de");
}

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, logger)));

config.set({
	prices: {
		abo: {
			EUR: 70,
			CHF: 68,
			GBP: 59,
			USD: 69
		},
		month: {
			EUR: 87,
			CHF: 96,
			GBP: 79,
			USD: 98
		},
		audio: {
			EUR: 17.5,
			CHF: 17,
			GBP: 14,
			USD: 18
		}
	},
	daysToNextAnalysis: 90
});

const app = (
	<Provider store={store}>
		<Language />
	</Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
