import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { createAccountStep1 } from "../../store/actions/index";

import Button from "../../ui/Button";
import Success from "../../ui/Success";
import Error from "../../ui/Error";
import { getRoute } from "../../shared/routes";

const CreateAccount = () => {
	const [eMail, set_eMail] = useState("");
	const [formSubmitEnabled, setFormSubmitEnabled] = useState(false);
	const checkEMailSent = useSelector((state) => state.auth.checkEMailSent);
	const language = useSelector((state) => state.app.language);
	const link = getRoute("createAccount", language);
	const inputRef = useRef();
	const intl = useIntl();
	const messages = defineMessages({
		mailSubject: { id: "createAccount.mailSubject", defaultMessage: "Dein Konto im Rico Brunner Kundenportal" },
		mailTextBeforeLink: {
			id: "createAccount.mailTextBeforeLink",
			defaultMessage: "Guten Tag\n\nKlicke auf diesen Link für den letzten Schritt zum Konto:\n"
		},
		mailTextAfterLink: {
			id: "createAccount.mailTextAfterLink",
			defaultMessage: "Dieser Link ist 1 Stunde gültig.\n\nFüge diese Adresse bitte zu deinen vertrauenswürdigen E-Mail Adressen hinzu, damit sie nicht im Spam landet.\n\nHerzliche Grüsse\nTeam Rico Brunner"
		},
		serverFailMailSubject: { id: "createAccount.serverFailMailSubject", defaultMessage: "Technische Probleme im Kundenportal von Rico Brunner behoben" },
		serverFailMailTextBeforeLink: {
			id: "createAccount.serverFailMailTextBeforeLink",
			defaultMessage: "Guten Tag\n\nDu wolltest dich bei uns registrieren als wir technische Probleme mit dem Server hatten. Dafür entschuldigen wir uns.\n\nUm deine Registration des Kontos abzuschliessen, klicke bitte auf folgenden Link:\n"
		},
		serverFailMailTextAfterLink: {
			id: "createAccount.serverFailMailTextAfterLink",
			defaultMessage: "Dieser Link ist 1 Stunde gültig.\n\nFüge diese Adresse bitte zu deinen vertrauenswürdigen E-Mail Adressen hinzu, damit sie nicht im Spam landet.\n\nHerzliche Grüsse\nTeam Rico Brunner"
		}
	});

	const dispatch = useDispatch();
	const createAccount = (eMail, mailSubject, mailTextBeforeLink, mailTextAfterLink, link, language) => dispatch(createAccountStep1(eMail, mailSubject, mailTextBeforeLink, mailTextAfterLink, link, language));

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const onSubmit = (event) => {
		event.preventDefault();
		createAccount(eMail, intl.formatMessage(messages.mailSubject), intl.formatMessage(messages.mailTextBeforeLink), intl.formatMessage(messages.mailTextAfterLink), link, language);
	};

	const changeEMail = (event) => {
		set_eMail(event.target.value);
		const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
		setFormSubmitEnabled(pattern.test(event.target.value));
	};

	return (
		<Fragment>
			<h1>
				<FormattedMessage id="createAccount.title" defaultMessage="Konto erstellen" />
			</h1>
			<Error />
			{checkEMailSent ? (
				<Success />
			) : (
				<Fragment>
					<p>
						<FormattedMessage
							id="createAccount.alreadyHaveAccount"
							defaultMessage="Behalte den Überblick über bestehende Fernbegleitungen und buche weitere jederzeit dazu. Melde dich <a>hier</a> an, wenn du bereits ein Konto besitzt."
							values={{ a: (...chunks) => <Link to="/">{chunks}</Link> }}
						/>
					</p>
					<strong>
						<FormattedMessage id="createAccount.importantMessage" defaultMessage="Wichtige Information" />
					</strong>
					<p>
						<FormattedMessage
							id="createAccount.alreadyCustomerPleaseUseExistingEMailAddress"
							defaultMessage="Bist du bereits Kunde von Rico Brunner?{br}Dann verwende bitte die gleiche E-Mail-Adresse, die du für die Korrespondenz mit uns bereits verwendest. Vielen Dank!"
							values={{
								br: <br />
							}}
						/>
					</p>
					<form onSubmit={onSubmit} className="spaceBefore">
						<label>
							<FormattedMessage id="createAccount.yourEMail" defaultMessage="Gib deine E-Mail Adresse für dein Konto ein" />
							<input type="text" ref={inputRef} value={eMail} onChange={changeEMail} />
						</label>
						<div className="bottomButtons spaceBefore">
							<div>
								<Button btnClass="default" home disabled={!formSubmitEnabled} type="submit">
									<FormattedMessage id="app.next" />
								</Button>
							</div>
						</div>
					</form>
				</Fragment>
			)}
		</Fragment>
	);
};

export default CreateAccount;
