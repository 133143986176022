import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MenuPoint from "../../components/Menu/MenuPoint/MenuPoint";

import Spinner from "../../ui/Spinner";
import Back from "../../components/Back/Back";
import AskPassword from "../../components/AskPassword/AskPassword";

import { authenticatedMenus } from "../../shared/structure";
import { getRoute } from "../../shared/routes";
import { appSetLanguage } from "../../store/actions/index";

const Layout = ({ children }) => {
	const language = useSelector((state) => state.app.language);
	const loading = useSelector((state) => state.app.loading);
	const sessionTimeOut = useSelector((state) => state.auth.sessionTimeOut);
	const isAuthenticated = useSelector((state) => state.auth.token !== null);
	const history = useHistory();
	const dispatch = useDispatch();
	const setLanguage = (language) => dispatch(appSetLanguage(language));
	const location = useLocation();
	const [menuOpen, setMenuOpen] = useState(false);

	const currentPath = location.pathname;

	const intl = useIntl();
	const menuPointsAuthentcated = authenticatedMenus(intl, language);

	const changeLanguage = (newLanguage) => {
		setLanguage(newLanguage);
		document.body.classList.remove("menuOpen");
		setMenuOpen(false);
	};

	useEffect(() => {
		setMenuOpen(false);
		document.body.classList.remove("menuOpen");
	}, [currentPath]);

	const toggleMenuOpen = () => {
		if (menuOpen) {
			document.body.classList.remove("menuOpen");
		} else {
			document.body.classList.add("menuOpen");
		}
		setMenuOpen((prev) => !prev);
	};

	const menuPointsArray = isAuthenticated ? menuPointsAuthentcated.filter((menuPoint) => menuPoint.name.length > 0).map((menuPoint) => ({ url: menuPoint.url, name: menuPoint.name, redirectTo: menuPoint.redirectTo })) : null;

	const menuPoints = menuPointsArray ? (
		menuPointsArray.map((menuPoint, index) => (
			<MenuPoint key={index} to={menuPoint.url + (menuPoint.redirectTo ? menuPoint.redirectTo : "")}>
				{menuPoint.name}
			</MenuPoint>
		))
	) : (
		<Fragment>
			<MenuPoint to="/">
				<FormattedMessage id="login.login" defaultMessage="Anmelden" />
			</MenuPoint>
			<MenuPoint to={getRoute("createAccount", language)} exactPath>
				<FormattedMessage id="login.createAccount" defaultMessage="Konto erstellen" />
			</MenuPoint>
			<MenuPoint to={getRoute("passwordForgotten", language)} exactPath>
				<FormattedMessage id="login.passwordForgotten" defaultMessage="Passwort vergessen" />
			</MenuPoint>
			<li>
				<span onClick={() => changeLanguage("de")} className={language === "de" ? "active" : ""}>
					DE
				</span>{" "}
				|{" "}
				<span onClick={() => changeLanguage("en")} className={language === "en" ? "active" : ""}>
					EN
				</span>
			</li>
		</Fragment>
	);

	const goHome = () => {
		if (isAuthenticated) {
			history.push(getRoute("activeTreatments", language));
		} else {
			history.push("/");
		}
	};

	return (
		<Fragment>
			<Header menuPoints={menuPoints} goHome={goHome} isAuthenticated={isAuthenticated} toggleMenuOpen={toggleMenuOpen} />
			<main>
				<div>
					<Back />
					{children}
				</div>
			</main>
			<footer>
				<nav>
					<Footer isAuthenticated={isAuthenticated} />
				</nav>
			</footer>
			{loading && <Spinner />}
			{sessionTimeOut && <AskPassword />}
		</Fragment>
	);
};

export default Layout;
