import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Button from "../../ui/Button";

const Datenschutzerklaerung = () => {
	const language = useSelector((state) => state.app.language);
	const link = language === "de" ? "https://app.rico-brunner.com/doc/de/DSE.pdf" : "https://app.rico-brunner.com/doc/en/Privacy_Policy.pdf";
	return (
		<div>
			<h1>
				<FormattedMessage id="datenschutzerklaerung.title" defaultMessage="Datenschutzerklärung" />
			</h1>
			<p>
				<FormattedMessage id="datenschutzerklaerung.intro" defaultMessage="Hier findest du unsere Datenschutzerklärung." />
			</p>
			<Button href={link}>
				<FormattedMessage id="datenschutzerklaerung.link" defaultMessage="Datenschutzerklärung als PDF herunterladen" />
			</Button>
		</div>
	);
};

export default Datenschutzerklaerung;
