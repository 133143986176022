import React from "react";
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import detectBrowserLanguage from "detect-browser-language";

import { appSetLanguage } from "./store/actions/index";

import App from "./App";

import messages_de from "./translations/de.json";
import messages_en from "./translations/en.json";
import { getLanguageOfPath } from "./shared/routes";

const Language = () => {
	const dispatch = useDispatch();
	const setLanguage = (language) => dispatch(appSetLanguage(language));
	const language = useSelector((state) => state.app.language);
	const country = useSelector((state) => state.user.country) || "CH";
	if (!language) {
		const pathItems = window.location.pathname.split("/");
		let newLanguage = "";

		if (pathItems.length > 1) {
			newLanguage = getLanguageOfPath("/" + pathItems[1]);
		}
		if (!newLanguage) {
			newLanguage = detectBrowserLanguage();
			if (detectBrowserLanguage().indexOf("de") === 0) {
				newLanguage = "de";
			} else {
				newLanguage = "en";
			}
		}
		setLanguage(newLanguage);
	}
	const messages = {
		de: {
			locale: "de-CH",
			messages: messages_de
		},
		en: {
			locale: country === "US" ? "en-US" : "en-GB",
			messages: messages_en
		}
	};

	document.documentElement.lang = language;

	return language ? (
		<IntlProvider locale={messages[language].locale} messages={messages[language].messages}>
			<BrowserRouter>
				<App language={language} />
			</BrowserRouter>
		</IntlProvider>
	) : null;
};

export default Language;
