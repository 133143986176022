import React, { Suspense, useEffect, useCallback, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import TagManager from "react-gtm-module";

import { getRoute } from "./shared/routes";

import Layout from "./hoc/Layout/Layout";
import Login from "./containers/Login/Login";
import CreateAccount from "./containers/CreateAccount/CreateAccount";
import CreateAccount2 from "./containers/CreateAccount/CreateAccount2";
import CreateAccount3 from "./containers/CreateAccount/CreateAccount3";
import PasswordForgotten from "./containers/PasswordForgotten/PasswordForgotten";
import SetNewPassword from "./containers/PasswordForgotten/SetNewPassword";
import EMailAdresseBestaetigung from "./containers/Konto/EMailAdresseWechseln/EMailAdresseBestaetigung";
import Fehler from "./containers/Fehler/Fehler";
import Danke from "./containers/Danke/Danke";
import Agb from "./containers/Agb/Agb";
import Datenschutzerklaerung from "./containers/Datenschutzerklaerung/Datenschutzerklaerung";
import Impressum from "./containers/Impressum/Impressum";
import ServiceMode from "./containers/ServiceMode/ServiceMode";

import { authenticatedMenus } from "./shared/structure";
import PageChange from "./components/PageChange/PageChange";

import { tryAutoSignup } from "./store/actions/index";

const tagManagerArgs = {
    gtmId: "GTM-5LLHJGS"
};

if (process.env.REACT_APP_USE_GTM === "true") {
    TagManager.initialize(tagManagerArgs);
}

const headerBackground = (event) => {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

    if (scrollTop > 20) {
        document.body.classList.add("scroll");
    } else {
        document.body.classList.remove("scroll");
    }
};

const App = ({ language }) => {
    const intl = useIntl();

    const overRideServiceMode = useSelector((state) => state.app.overRideServiceMode);
    const autoSignupTried = useSelector((state) => state.auth.autoSignupTried);
    const redirect = useSelector((state) => state.app.redirect);
    const isAuthenticated = useSelector((state) => state.auth.token !== null);
    const menus = isAuthenticated ? authenticatedMenus(intl, language) : null;
    const dispatch = useDispatch();
    const onTryAutoSignup = useCallback(() => dispatch(tryAutoSignup()), [dispatch]);

    useEffect(() => {
        window.addEventListener("scroll", headerBackground);
        return () => {
            window.removeEventListener("scroll", headerBackground);
        };
    }, []);

    useEffect(() => {
        onTryAutoSignup();
    }, [onTryAutoSignup]);

    let routerRoutes = (
        <Switch>
            <Route path="/" exact component={Login} />
            <Route path={getRoute("createAccount", "de")} exact component={CreateAccount} />
            <Route path={getRoute("createAccount", "en")} exact component={CreateAccount} />

            <Route path={getRoute("createAccount", "de") + "/:key"} exact component={CreateAccount2} />
            <Route path={getRoute("createAccount", "en") + "/:key"} exact component={CreateAccount2} />

            <Route path={getRoute("finishCreateAccount", "de")} exact component={CreateAccount3} />
            <Route path={getRoute("finishCreateAccount", "en")} exact component={CreateAccount3} />

            <Route path={getRoute("passwordForgotten", "de")} exact component={PasswordForgotten} />
            <Route path={getRoute("passwordForgotten", "en")} exact component={PasswordForgotten} />

            <Route path={getRoute("newPassword", "de") + "/:key"} exact component={SetNewPassword} />
            <Route path={getRoute("newPassword", "en") + "/:key"} exact component={SetNewPassword} />

            <Route path={getRoute("confirmEMail", "de") + "/:key"} exact component={EMailAdresseBestaetigung} />
            <Route path={getRoute("confirmEMail", "en") + "/:key"} exact component={EMailAdresseBestaetigung} />

            <Route path={getRoute("error", "de")} exact component={Fehler} />
            <Route path={getRoute("error", "en")} exact component={Fehler} />

            <Route path={getRoute("thanks", "de")} exact component={Danke} />
            <Route path={getRoute("thanks", "en")} exact component={Danke} />

            <Route path={getRoute("agb", "de")} exact component={Agb} />
            <Route path={getRoute("agb", "en")} exact component={Agb} />

            <Route path={getRoute("privacyStatement", "de")} exact component={Datenschutzerklaerung} />
            <Route path={getRoute("privacyStatement", "en")} exact component={Datenschutzerklaerung} />

            <Route path={getRoute("imprint", "de")} exact component={Impressum} />
            <Route path={getRoute("imprint", "en")} exact component={Impressum} />

            <Route render={() => <Redirect to="/" />} />
        </Switch>
    );

    if (menus) {
        routerRoutes = [];

        menus.forEach((menu, index) => {
            routerRoutes.push(<Route key={index} path={menu.url + (menu.redirectTo ? menu.redirectTo : "")} exact component={React.lazy(() => import("./containers/" + menu.component))} />);
            if (menu.menus) {
                menu.menus.forEach((menu2, index) => {
                    if (menu2.component) {
                        routerRoutes.push(<Route key={"_" + index} path={menu.url + menu2.url} exact component={React.lazy(() => import("./containers/" + menu2.component))} />);
                        if (menu2.menus) {
                            menu2.menus.forEach((menu3, index) => {
                                if (menu3.component) {
                                    routerRoutes.push(<Route key={"_2_" + index} path={menu.url + menu2.url + menu3.url} exact component={React.lazy(() => import("./containers/" + menu3.component))} />);
                                    if (menu3.menus) {
                                        menu3.menus.forEach((menu4, index) => {
                                            if (menu4.component) {
                                                routerRoutes.push(<Route key={"_3_" + index} path={menu.url + menu2.url + menu3.url + menu4.url} component={React.lazy(() => import("./containers/" + menu4.component))} />);
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    }
                });
            }
        });

        routerRoutes.push(<Route key="agb" path={getRoute("agb", language)} exact component={Agb} />);
        routerRoutes.push(<Route key="privacyStatement" path={getRoute("privacyStatement", language)} exact component={Datenschutzerklaerung} />);
        routerRoutes.push(<Route key="imprint" path={getRoute("imprint", language)} exact component={Impressum} />);
        routerRoutes.push(<Route key="error" path={getRoute("error", language)} exact component={Fehler} />);
        routerRoutes.push(<Route key="thanks" path={getRoute("thanks", language)} exact component={Danke} />);
        routerRoutes.push(<Route key="confirmNewEMail" path={getRoute("confirmEMail", language) + "/:key"} exact component={EMailAdresseBestaetigung} />);
        routerRoutes.push(<Redirect key="start" to={getRoute("activeTreatments", language)} />);
    }

    if (process.env.REACT_APP_SERVICE_MODE === "true" && !overRideServiceMode) {
        return <ServiceMode />;
    } else {
        return (
            <Layout>
                <Suspense fallback={<p>Loading...</p>}>
                    {autoSignupTried && (
                        <Fragment>
                            <PageChange />
                            {menus ? <Switch>{routerRoutes}</Switch> : routerRoutes}
                            {redirect && <Redirect to={redirect} />}
                        </Fragment>
                    )}
                </Suspense>
            </Layout>
        );
    }
};

export default App;
