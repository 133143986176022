import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as Icon } from "../../assets/images/cart.svg";
import { getRoute } from "../../shared/routes";

const CartIcon = () => {
	const cart = useSelector((state) => state.user.cart);
	const language = useSelector((state) => state.app.language);
	return (
		cart.length > 0 && (
			<Link to={getRoute("orderOverview", language)} className="cartIcon">
				<Icon />
				<div>{cart.length}</div>
			</Link>
		)
	);
};

export default CartIcon;
