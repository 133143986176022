import { useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";
import pluginConfig from "./cookieConsentConfig";
import { FormattedMessage } from "react-intl";
import "vanilla-cookieconsent/dist/cookieconsent.css";

const CookieConsentComponent = () => {
    useEffect(() => {
        CookieConsent.run(pluginConfig);
    }, []);

    return (
        <span onClick={CookieConsent.showPreferences}>
            <FormattedMessage id="menu.cookieSettins" defaultMessage="COOKIE-EINSTELLUNGEN" />
        </span>
    );
};

export default CookieConsentComponent;
