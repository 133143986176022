import ReactGA from "react-ga4";
import * as CookieConsent from "vanilla-cookieconsent";

const pluginConfig = {
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },

    // onFirstConsent: function () {
    //     console.log("onFirstAction fired");
    // },

    // onConsent: function ({ cookie }) {
    //     console.log("onConsent fired ...");
    // },

    onChange: function ({ changedCategories, cookie }) {
        if (changedCategories.includes("analytics")) {
            if (CookieConsent.acceptedCategory("analytics")) {
                ReactGA.gtag("consent", "update", {
                    analytics_storage: "granted"
                });
            } else {
                ReactGA.gtag("consent", "update", {
                    analytics_storage: "denied"
                });
            }
        }

        if (changedCategories.includes("advertising")) {
            if (CookieConsent.acceptedCategory("advertising")) {
                ReactGA.gtag("consent", "update", {
                    ad_storage: "granted",
                    ad_personalization: "granted"
                });
            } else {
                ReactGA.gtag("consent", "update", {
                    ad_storage: "denied",
                    ad_personalization: "denied"
                });
            }
        }
    },

    categories: {
        necessary: {
            readOnly: true
        },
        advertising: {},
        analytics: {}
    },

    language: {
        default: "de",
        autoDetect: "browser",
        translations: {
            de: {
                consentModal: {
                    title: "Hallo!",
                    description:
                        "Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Ausserdem geben wir Informationen zu deiner Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die du ihnen bereitgestellt hast oder die sie im Rahmen deiner Nutzung der Dienste gesammelt haben. \nWeitere Informationen:<a href='/de/datenschutz'>Datenschutzerklärung</a> /<a href='/de/impressum'>Impressum</a> \n",
                    closeIconLabel: "",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    showPreferencesBtn: "Einstellungen verwalten",
                    footer: '<a href="/datenschutzerklaerung">Datenschutz</a>\n<a href="/agb">AGB und Widerruf</a>'
                },
                preferencesModal: {
                    title: "Präferenzen für die Zustimmung",
                    closeIconLabel: "Modal schließen",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    savePreferencesBtn: "Einstellungen speichern",
                    serviceCounterLabel: "Dienstleistungen",
                    sections: [
                        {
                            title: "Cookie Erklärung",
                            description:
                                "Cookies sind kleine Textdateien, die von Webseiten verwendet werden, um die Benutzererfahrung effizienter zu gestalten. Laut Gesetz können wir Cookies auf deinem Gerät speichern, wenn diese für den Betrieb dieser Seite unbedingt notwendig sind. Für alle anderen Cookie-Typen benötigen wir deine Erlaubnis. Diese Seite verwendet unterschiedliche Cookie-Typen. Einige Cookies werden von Drittparteien platziert, die auf unseren Seiten erscheinen.\nDu kannst deine Einwilligung jederzeit von der Cookie-Erklärung auf unserer Website ändern oder widerrufen. ",
                            linkedCategory: "necessary"
                        },
                        {
                            title: 'Notwendige Cookies <span class="pm__badge">Immer Aktiviert</span>',
                            description:
                                "Pflichtfeld - kann nicht abgewählt werden. Notwendige Cookies helfen dabei, die Videos auf der  Webseite nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Das Abspielen der Videos kann ohne diese Cookies nicht richtig funktionieren. ",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Analytische Cookies",
                            description: "Analytische Cookies helfen uns zu verstehen, wie Besucher mit Webseiten interagieren, indem Informationen anonym gesammelt und ausgewertet werden.",
                            linkedCategory: "analytics"
                        },
                        {
                            title: "Marketing Cookies",
                            description:
                                'Marketing-Cookies erfassen Informationen über das Online-Verhalten von Besuchern, um die Auslieferung von Werbung zu optimieren. Sie zielen darauf ab, Werbeinhalte anzuzeigen, die für die Präferenzen des Nutzers potenziell interessanter sind, und unterstützen so eine effizientere Werbekommunikation zwischen Webseitenbetreibern und Nutzern.\n\nBei Fragen zu den Cookies und den Wahlmöglichkeiten wende dich bitte an <a class="cc__link" href="@rico-brunner.com">uns</a>.',
                            linkedCategory: "advertising"
                        }
                    ]
                }
            }
        }
    }
};

export default pluginConfig;
