import * as actionTypes from "./actionTypes";
import axios from "../../rbAxios";
import { appStartLoading, appStopLoading, appSetError, appSetSuccess, appSetNote, appSetRedirect } from "./app";
import { userGetData, userClearData, saveData } from "./user";
import timeDiff from "../../shared/timeDiff";

export const authStart = () => {
	return {
		type: actionTypes.AUTH_START
	};
};

export const authFail = () => {
	return {
		type: actionTypes.AUTH_FAIL
	};
};

const authLogout = () => {
	return {
		type: actionTypes.AUTH_LOGOUT
	};
};

export const logout = () => {
	localStorage.removeItem("token");
	localStorage.removeItem("refreshToken");
	return (dispatch) => {
		dispatch(authLogout());
		dispatch(userClearData());
	};
};

export const checkAuthTimeout = (expirationTime) => {
	return (dispatch) => {
		setTimeout(() => {
			dispatch(logout());
		}, expirationTime * 1000);
	};
};

export const auth = (eMail, password) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const authData = {
			eMail: eMail,
			password: password
		};

		axios
			.post("/login", authData)
			.then((response) => {
				const sessionInterval = startSessionChecker(dispatch);
				dispatch(setSessionInterval(sessionInterval));
				dispatch(updateTokens(response.data));
				dispatch(userGetData());
				/* dispatch(checkAuthTimeout(response.data.expiresIn)); */
			})
			.catch((err) => {
				dispatch(authFail());
				dispatch(appStopLoading());
				dispatch(appSetError("eMailOrPasswordWrong"));
			});
	};
};

export const reAuth = (password, token) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const headers = {
			Authorization: "Bearer " + token
		};
		const authData = {
			password: password
		};
		axios
			.post("/api/reAuth", authData, headers)
			.then((response) => {
				const sessionInterval = startSessionChecker(dispatch);
				dispatch(setSessionInterval(sessionInterval));
				dispatch(updateTokens(response.data));
				dispatch(restartSession());
				dispatch(appStopLoading());
			})
			.catch((err) => {
				dispatch(appStopLoading());
				dispatch(appSetError("passwordWrong"));
			});
	};
};

export const restartSession = () => {
	return {
		type: actionTypes.AUTH_RESTART_SESSION
	};
};

export const authPasswordForgotten = (eMail, mailSubject, mailTextBeforeLink, mailTextAfterLink, link) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const data = {
			eMail: eMail,
			mailSubject: mailSubject,
			mailTextBeforeLink: mailTextBeforeLink,
			mailTextAfterLink: mailTextAfterLink,
			link: link
		};

		axios
			.post("/passwordForgotten", data)
			.then((response) => {
				dispatch(appStopLoading());
				dispatch(appSetRedirect("/"));
				dispatch(
					appSetSuccess({
						key: "passwordForgottenEMailSentToXY",
						values: { eMail: eMail }
					})
				);
			})
			.catch((err) => {
				dispatch(appStopLoading());
				dispatch(appSetRedirect("/"));
				dispatch(
					appSetSuccess({
						key: "passwordForgottenEMailSentToXY",
						values: { eMail: eMail }
					})
				);
			});
	};
};

export const authSetNewForgottenPassword = (password, key) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const data = {
			password: password
		};

		axios
			.patch("/setNewForgottenPassword/" + key, data)
			.then((response) => {
				dispatch(appStopLoading());
				dispatch(appSetRedirect("/"));
				dispatch(appSetSuccess("passwordChangedSuccessfully"));
			})
			.catch((err) => {
				dispatch(appStopLoading());
				dispatch(appSetRedirect("/"));
				dispatch(appSetError("savePasswordFailed"));
			});
	};
};

const checkSession = () => {
	return {
		type: actionTypes.AUTH_CHECK_SESSION
	};
};

export const startSessionChecker = (dispatch) => {
	return window.setInterval(() => {
		dispatch(checkSession());
	}, 1000);
};

export const stopSessionChecker = () => {
	return {
		type: actionTypes.AUTH_STOP_SESSION_CHECKER
	};
};

export const setSessionInterval = (sessionInterval) => {
	return {
		type: actionTypes.AUTH_SET_SESSIONINTERVAL,
		sessionInterval: sessionInterval
	};
};

export const setAutoSignupTried = () => {
	return {
		type: actionTypes.AUTH_AUTOSIGNUP_TRIED
	};
};

export const tryAutoSignup = () => {
	return (dispatch) => {
		const token = localStorage.getItem("token");
		const refreshToken = localStorage.getItem("refreshToken");
		if (!token) {
			dispatch(logout());
		} else {
			const sessionInterval = startSessionChecker(dispatch);
			dispatch(setSessionInterval(sessionInterval));
			dispatch(updateTokens({ token: token, refreshToken: refreshToken, time: 0 }));
			dispatch(userGetData());
		}
	};
};

export const updateTokens = ({ token, refreshToken, time }) => {
	localStorage.setItem("token", token);
	localStorage.setItem("refreshToken", refreshToken);
	if (time) {
		timeDiff.timeDiff = new Date().getTime() - time;
	}
	return {
		type: actionTypes.AUTH_UPDATE_TOKENS,
		token: token,
		refreshToken: refreshToken
	};
};

const checkEMailSent = (success) => {
	return {
		type: actionTypes.AUTH_CHECK_EMAIL_SENT,
		success: success
	};
};

export const createAccountStep1 = (eMail, mailSubject, mailTextBeforeLink, mailTextAfterLink, link, language) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const data = {
			eMail: eMail,
			mailTextBeforeLink: mailTextBeforeLink,
			mailTextAfterLink: mailTextAfterLink,
			mailSubject: mailSubject,
			link: link,
			language: language
		};

		axios
			.post("/createAccountStep1", data)
			.then((response) => {
				console.log(response);
				dispatch(checkEMailSent(response.data.success === true));
				dispatch(appSetSuccess("createAccountStep1Success"));
				dispatch(appStopLoading());
			})
			.catch((err) => {
				dispatch(appSetRedirect("/"));
				dispatch(appSetError(err.response.data.error));
				dispatch(appStopLoading());
			});
	};
};

const saveUniqueKey = (uniqueKey) => {
	return {
		type: actionTypes.AUTH_SAVE_UNIQUE_KEY,
		uniqueKey: uniqueKey
	};
};

export const saveCountryData = (countryData) => {
	return {
		type: actionTypes.AUTH_SAVE_COUNTRY_DATA,
		countryData: countryData
	};
};

export const confirmEMailKey = (key, serverFailMailSubject, serverFailMailTextBeforeLink, serverFailMailTextAfterLink, redirect, redirectOnError) => {
	return (dispatch) => {
		dispatch(appStartLoading());
		const data = {
			serverFailMailSubject: serverFailMailSubject,
			serverFailMailTextBeforeLink: serverFailMailTextBeforeLink,
			serverFailMailTextAfterLink: serverFailMailTextAfterLink
		};
		axios
			.post("/confirmEMailKey/" + key, data)
			.then((response) => {
				if (response.data.jsonData) {
					dispatch(saveData(response.data));
				}
				dispatch(saveCountryData(response.data.countryData));
				dispatch(saveUniqueKey(response.data.uniqueKey));
				dispatch(appSetRedirect(redirect));
				dispatch(appSetNote(response.data.note));
				dispatch(appStopLoading());
			})
			.catch((err) => {
				dispatch(appSetRedirect(redirectOnError));
				dispatch(appStopLoading());
			});
	};
};
