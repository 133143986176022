import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/images/logo_rico-brunner-ag-pos.svg";
import CartIcon from "../../components/CartIcon/CartIcon";
import HamburgerMenu from "../../ui/HamburgerMenu";
import Footer from "../Footer/Footer";

const Header = ({ menuPoints, goHome, isAuthenticated, toggleMenuOpen }) => {
	const location = useLocation();

	const currentPath = location.pathname;

	if (currentPath === "/") {
		document.body.classList.add("home");
	} else {
		document.body.classList.remove("home");
	}

	useEffect(() => {
		if (isAuthenticated) {
			document.body.classList.remove("notAuthenticated");
		} else {
			document.body.classList.add("notAuthenticated");
		}
	}, [isAuthenticated]);

	return (
		<header>
			<Logo className="logo" onClick={goHome} alt="Rico Brunner" />
			<HamburgerMenu toggleMenu={toggleMenuOpen} />
			<CartIcon />
			<nav>
				<ul>{menuPoints}</ul>
				<Footer isAuthenticated={isAuthenticated} />
			</nav>
		</header>
	);
};

export default Header;
