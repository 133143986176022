import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import uuid from "react-uuid";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import ReactPasswordStrength from "react-password-strength";

import { appClearFieldError, createCustomer, importCustomer, appSetError } from "../../store/actions/index";

import FieldError from "../../ui/FieldError";
import Button from "../../ui/Button";
import Error from "../../ui/Error";
import Success from "../../ui/Success";
import Note from "../../ui/Note";
import { getRoute } from "../../shared/routes";

import { ReactComponent as Switzerland } from "../../assets/images/switzerland.svg";
import { ReactComponent as Germany } from "../../assets/images/germany.svg";

import { newAddressFields } from "../../formElements/address";

const labels = defineMessages({
	yes: { id: "app.yes", defaultMessage: "Ja" },
	gender: { id: "address.gender", defaultMessage: "Geschlecht" },
	female: { id: "address.female", defaultMessage: "Weiblich" },
	male: { id: "address.male", defaultMessage: "Männlich" },
	divers: { id: "address.divers", defaultMessage: "Divers" },
	firstname: { id: "address.firstname", defaultMessage: "Vorname" },
	lastname: { id: "address.lastname", defaultMessage: "Nachname" },
	street: { id: "address.street", defaultMessage: "Strasse & Hausnummer" },
	streetAddition: { id: "address.streetAddition", defaultMessage: "Postfach etc." },
	zip: { id: "address.zip", defaultMessage: "PLZ" },
	city: { id: "address.city", defaultMessage: "Ort" },
	country: { id: "address.country", defaultMessage: "Land" },
	ch: { id: "address.ch", defaultMessage: "Schweiz" },
	li: { id: "address.li", defaultMessage: "Liechtenstein" },
	de: { id: "address.de", defaultMessage: "Deutschland" },
	at: { id: "address.at", defaultMessage: "Österreich" },
	phonePrivate: { id: "address.phonePrivate", defaultMessage: "Telefonnummer Privat" },
	phoneBusiness: { id: "address.phoneBusiness", defaultMessage: "Telefonnummer Geschäft" },
	mobile: { id: "address.mobile", defaultMessage: "Mobile" },
	language: { id: "address.language", defaultMessage: "Sprache" },
	correspondenceLanguage: { id: "address.correspondenceLanguage", defaultMessage: "Korrespondenzsprache" },
	audioLanguage: { id: "address.audioLanguage", defaultMessage: "Analyse (Audiodatei von Rico Brunner) in" },
	currency: { id: "address.currency", defaultMessage: "Währung" },
	chf: { id: "app.CHF", defaultMessage: "CHF" },
	euro: { id: "app.EURO", defaultMessage: "€" },
	newsletter: { id: "address.newsletter", defaultMessage: "Newsletter" },
	german: { id: "app.german", defaultMessage: "Deutsch" },
	english: { id: "app.english", defaultMessage: "English" },
	swissGerman: { id: "app.swissGerman", defaultMessage: "Schweizerdeutsch" },
	highGerman: { id: "app.highGerman", defaultMessage: "Hochdeutsch" },
	password: { id: "address.password", defaultMessage: "Passwort" },
	passwordConfirmation: { id: "address.passwordConfirmation", defaultMessage: "Passwort (Bestätigung)" },
	pleaseChoose: { id: "address.pleaseChoose", defaultMessage: "Bitte wählen..." },
	passwordWeak: { id: "address.passwordWeak", defaultMessage: "sehr unsicher" },
	passwordWeakBetter: { id: "address.passwordWeakBetter", defaultMessage: "unsicher" },
	passwordOk: { id: "address.passwordOk", defaultMessage: "gut" },
	passwordGood: { id: "address.passwordGood", defaultMessage: "stark" },
	passwordStrong: { id: "address.passwordStrong", defaultMessage: "sehr stark" },
	passwordToShort: { id: "address.passwordToShort", defaultMessage: "zu kurz" }
});

const CreateAccount3 = () => {
	const intl = useIntl();
	const data = useSelector((state) => state.user.data);
	const address = data === null ? null : data.fieldData !== "undefined" ? data.fieldData : [];
	const [createAccountPossible, setCreateAccountPossible] = useState(data !== null);
	const [passwordValid, setPasswordValid] = useState(false);
	const uniqueKey = useSelector((state) => state.auth.uniqueKey);
	const language = useSelector((state) => state.app.language);
	const countryData = useSelector((state) => state.auth.countryData);
	const dispatch = useDispatch();
	const dipatchClearFieldError = (field) => dispatch(appClearFieldError(field));
	const setError = (error, FieldErrors, clearErrors) => dispatch(appSetError(error, FieldErrors, clearErrors));
	const history = useHistory();
	const dispatchCreateCustomer = (uniqueKey, address, UUIDToBeTreated, redirect) => dispatch(createCustomer(uniqueKey, address, UUIDToBeTreated, redirect));
	const dispatchImportCustomer = (uniqueKey, address, fmData, redirect) => dispatch(importCustomer(uniqueKey, address, fmData, redirect));

	const addressFields = newAddressFields();
	if (address) {
		Object.entries(addressFields).forEach(([key, field]) => {
			if (address[field.fmName]) {
				addressFields[key].value = address[field.fmName];
			}
		});
	}

	const [addressData, setAddressData] = useState({ ...addressFields, language: { ...addressFields.language, value: language }, audioLanguage: { ...addressFields.audioLanguage, required: language === "de" } });

	useEffect(() => {
		if (uniqueKey === null) {
			history.push("/");
		}
	}, [uniqueKey, history]);

	const onSubmit = (event) => {
		event.preventDefault();
		const fieldErrors = {};
		const newAddress = {};

		if (!passwordValid) {
			fieldErrors.password = "passwordError";
		}

		for (const [field, content] of Object.entries(addressData)) {
			if (field === "password2") {
				if (content.value !== addressData.password.value && fieldErrors.password === undefined) {
					fieldErrors[field] = "passwordNotEqual";
				}
			} else if (content.required && content.value.trim().length < 1) {
				fieldErrors[field] = content.error;
			}
		}

		if (Object.entries(fieldErrors).length === 0) {
			for (const [field, content] of Object.entries(addressData)) {
				if (content.fmName) {
					newAddress[content.fmName] = content.value;
				} else {
					newAddress[field] = content.value;
				}
			}
			newAddress[addressData.currency.fmName] = countryData.find((country) => country.countryCode === addressData.country.value).currency;
			if (address === null) {
				newAddress.UUIDKunde = uuid();
				dispatchCreateCustomer(uniqueKey, newAddress, uuid(), getRoute("createAccount", language));
			} else {
				dispatchImportCustomer(uniqueKey, newAddress, data, getRoute("createAccount", language));
			}
		} else {
			setError(null, fieldErrors, true);
		}
		window.scrollTo(0, 0);
	};

	const alreadyCustomerOtherEMail = () => {
		setError("createAccountAlreadyCustomerOtherMailAddress");
		setCreateAccountPossible("no");
	};

	const change = (event) => {
		const fieldname = event.target.name;
		const fieldvalue = event.target.value;

		setAddressData((prev) => ({ ...prev, [fieldname]: { ...prev[fieldname], value: fieldvalue } }));
		if (fieldname === "language") {
			setAddressData((prev) => ({ ...prev, audioLanguage: { ...prev.audioLanguage, required: fieldvalue === "de" } }));
		}
		if (addressData[fieldname].required) {
			dipatchClearFieldError(fieldname);
		}
	};

	const changePassword = (state) => {
		dipatchClearFieldError("newPassword");
		setAddressData((prev) => ({ ...prev, password: { ...prev.password, value: state.password } }));
		setPasswordValid(state.isValid);
	};

	let result = null;
	let title = <FormattedMessage id="createAccount.finish" defaultMessage="Aktiviere dein Konto" />;
	if (createAccountPossible === false) {
		title = <FormattedMessage id="createAccount.alreadyCustomer" defaultMessage="Bist Du bereits Kunde von Rico Brunner?" />;
		result = (
			<div>
				<div className="rightButtonRow spaceBefore">
					<Button type="button" action={alreadyCustomerOtherEMail}>
						<FormattedMessage id="app.yes" />
					</Button>
					<Button type="button" action={() => setCreateAccountPossible("yes")}>
						<FormattedMessage id="app.no" defaultMessage="Nein" />
					</Button>
				</div>
			</div>
		);
	} else if (createAccountPossible === true || createAccountPossible === "yes") {
		result = (
			<Fragment>
				<Note marginTop />
				<p>
					<FormattedMessage id="createAccount.privacy" defaultMessage="Deine Daten werden nicht an Dritte weitergegeben. Du kannst deine Daten jederzeit bearbeiten und dein Konto löschen. Bei Fragen hilft dir die persönliche Mitarbeiterin von Rico Brunner gerne jederzeit weiter." />
				</p>
				<form className="editAddress" onSubmit={onSubmit}>
					<div>
						<label className="field required">
							{intl.formatMessage(labels.language)}
							<div className="radioGroup">
								<label className="radio">
									<input type="radio" name="language" value="de" checked={addressData.language.value === "de"} onChange={change} />
									{intl.formatMessage(labels.german)}
									<div className="btn"></div>
								</label>
								<label className="radio">
									<input type="radio" name="language" value="en" checked={addressData.language.value === "en"} onChange={change} />
									{intl.formatMessage(labels.english)}
									<div className="btn"></div>
								</label>
							</div>
						</label>
					</div>
					<div>
						<label className="field required">
							{intl.formatMessage(labels.gender)}
							<div className="radioGroup">
								<label className="radio">
									<input type="radio" name="gender" value="w" checked={addressData.gender.value === "w"} onChange={change} />
									{intl.formatMessage(labels.female)}
									<div className="btn"></div>
								</label>
								<label className="radio">
									<input type="radio" name="gender" value="m" checked={addressData.gender.value === "m"} onChange={change} />
									{intl.formatMessage(labels.male)}
									<div className="btn"></div>
								</label>
								<label className="radio">
									<input type="radio" name="gender" value="d" checked={addressData.gender.value === "d"} onChange={change} />
									{intl.formatMessage(labels.divers)}
									<div className="btn"></div>
								</label>
							</div>
							<FieldError field="gender" />
						</label>
					</div>
					<div>
						<label className="field required">
							{intl.formatMessage(labels.firstname)}
							<div>
								<input name="firstname" type="text" autoComplete="off" value={addressData.firstname.value} onChange={change} />
							</div>
							<FieldError field="firstname" />
						</label>
					</div>
					<div>
						<label className="field required">
							{intl.formatMessage(labels.lastname)}
							<div>
								<input name="lastname" type="text" autoComplete="off" value={addressData.lastname.value} onChange={change} />
							</div>
							<FieldError field="lastname" />
						</label>
					</div>
					<div>
						<label className="field required">
							{intl.formatMessage(labels.street)}
							<div>
								<input name="street" type="text" autoComplete="off" value={addressData.street.value} onChange={change} />
							</div>
							<FieldError field="street" />
						</label>
					</div>
					<div>
						<label className="field">
							{intl.formatMessage(labels.streetAddition)}
							<div>
								<input name="streetAddition" type="text" autoComplete="off" value={addressData.streetAddition.value} onChange={change} />
							</div>
						</label>
					</div>
					<div>
						<label className="field required">
							{intl.formatMessage(labels.zip)}
							<div>
								<input name="zip" type="text" autoComplete="off" value={addressData.zip.value} onChange={change} />
							</div>
							<FieldError field="zip" />
						</label>
					</div>
					<div>
						<label className="field required">
							{intl.formatMessage(labels.city)}
							<div>
								<input name="city" type="text" autoComplete="off" value={addressData.city.value} onChange={change} />
							</div>
							<FieldError field="city" />
						</label>
					</div>
					<div>
						<label className="field required">
							{intl.formatMessage(labels.country)}
							<div className="select">
								<select name="country" value={addressData.country.value} onChange={change}>
									<option value=""> {intl.formatMessage(labels.pleaseChoose)}</option>
									{countryData.map((country) => (
										<option key={country.countryCode} value={country.countryCode}>
											{country["countryName_" + language]}
										</option>
									))}
								</select>
							</div>
							<FieldError field="country" />
						</label>
					</div>
					<div>
						<label className="field">
							{intl.formatMessage(labels.phonePrivate)}
							<div>
								<input name="phonePrivate" type="text" autoComplete="off" value={addressData.phonePrivate.value} onChange={change} />
							</div>
						</label>
					</div>
					<div>
						<label className="field">
							{intl.formatMessage(labels.phoneBusiness)}
							<div>
								<input name="phoneBusiness" type="text" autoComplete="off" value={addressData.phoneBusiness.value} onChange={change} />
							</div>
						</label>
					</div>
					<div>
						<label className="field">
							{intl.formatMessage(labels.mobile)}
							<div>
								<input name="mobile" type="text" autoComplete="off" value={addressData.mobile.value} onChange={change} />
							</div>
						</label>
					</div>
					{addressData.language.value === "de" && (
						<div>
							<label className="field required">
								{intl.formatMessage(labels.audioLanguage)}
								<div className="radioGroup">
									<label className="radio">
										<input type="radio" name="audioLanguage" value="Schweizerdeutsch" onChange={change} />
										<Switzerland className="swiss" />
										{intl.formatMessage(labels.swissGerman)}
										<div className="btn"></div>
									</label>
									<label className="radio">
										<input type="radio" name="audioLanguage" value="Hochdeutsch" onChange={change} />
										<Germany />
										{intl.formatMessage(labels.german)}
										<div className="btn"></div>
									</label>
								</div>
								<FieldError field="audioLanguage" />
							</label>
						</div>
					)}
					<div>
						<label className="field required">
							{intl.formatMessage(labels.password)}
							<div>
								<ReactPasswordStrength
									minLength={8}
									minScore={3}
									scoreWords={[intl.formatMessage(labels.passwordWeak), intl.formatMessage(labels.passwordWeakBetter), intl.formatMessage(labels.passwordOk), intl.formatMessage(labels.passwordGood), intl.formatMessage(labels.passwordStrong)]}
									tooShortWord={intl.formatMessage(labels.passwordToShort)}
									changeCallback={changePassword}
								/>
							</div>
							<FieldError field="password" />
						</label>
					</div>
					<div>
						<label className="field required">
							{intl.formatMessage(labels.passwordConfirmation)}
							<div>
								<input name="password2" type="password" autoComplete="off" value={addressData.password2.value} onChange={change} />
							</div>
							<FieldError field="password2" />
						</label>
					</div>
					<br />
					<Button type="submit">
						<FormattedMessage id="app.save" />
					</Button>
				</form>
			</Fragment>
		);
	}

	return (
		<div>
			<h1>{title}</h1>
			<Error marginTop />
			<Success marginTop />
			{result}
		</div>
	);
};

export default CreateAccount3;
