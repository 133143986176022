import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { defineMessages, FormattedMessage } from "react-intl";

import { ReactComponent as Icon } from "../assets/images/error.svg";

import { appClearError } from "../store/actions/index";

const Error = ({ marginTop }) => {
	const messages = defineMessages({
		createAccountCompleteRegistrationWithinOneHour: {
			id: "createAccount.eMailNotFound",
			defaultMessage: "Die Registration deines Kontos konnte nicht abgeschlossen werden! Bitte gib deine E-Mail Adresse erneut an und komplettiere den Registrationsprozess innert einer Stunde."
		},
		createAccountNotAllowed: {
			id: "createAccount.createAccountNotAllowed",
			defaultMessage: "Die Registration deines Kontos konnte leider nicht abgeschlossen werden. Bitte wende dich an unseren Support: info@rico-brunner.com. Wir helfen dir gerne weiter."
		},
		createAccountNotPossible: {
			id: "createAccount.createAccountNotPossible",
			defaultMessage: "Leider konnte die Registration nicht abgeschlossen werden, da ein technisches Problem aufgetreten ist. Wir entschuldigen uns für diesen Umstand. Sobald die Probleme behoben sind wirst Du automatisch per E-Mail benachrichtigt!"
		},
		createAccountYouHaveAlreadyCustomerPortal: {
			id: "createAccount.youHaveAlreadyCustomerPortal",
			defaultMessage: "Du hast bereits ein Konto bei uns. Bitte melde dich an:"
		},
		createAccountEMailAlreadySent: {
			id: "createAccount.eMailAlreadySent",
			defaultMessage: "Wir haben bereits eine E-Mail zur Bestätigung deiner Indentität an dich gesandt."
		},
		createAccountAlreadyCustomerOtherMailAddress: {
			id: "createAccount.createAccountAlreadyCustomerOtherMailAddress",
			defaultMessage:
				"Wahrscheinlich bist du bei uns mit einer anderen E-Mail Adresse erfasst. Wenn du tatsächlich noch eine andere E-Mail Adresse besitzt, versuche mit dieser, erneut ein Konto zu erstellen. Ansonsten wende dich bitte an unseren Support info@rico-brunner.com. Wir helfen dir gerne weiter."
		},
		eMailOrPasswordWrong: {
			id: "login.eMailOrPasswordWrong",
			defaultMessage: "Die E-Mail Adresse oder das Passwort ist falsch!"
		},
		savePasswordFailed: {
			id: "login.savePasswordFailed",
			defaultMessage: "Beim Speichern deines neuen Passwortes ist ein Fehler aufgetreten!"
		},
		passwordWrong: {
			id: "app.passwordWrong",
			defaultMessage: "Das angegebene Passwort ist falsch!"
		},
		eMailNoChange: {
			id: "address.eMailNoChange",
			defaultMessage: "Diese E-Mail Adresse verwendest Du bereits im Kundenportal!"
		},
		eMailInvalid: {
			id: "address.eMailInvalid",
			defaultMessage: "Die angegebene E-Mail Adresse ist ungültig!"
		},
		errorContactRB: {
			id: "app.errorContactRB",
			defaultMessage: "Es ist ein Fehler aufgetreten. Bitte kontaktiere uns telefonisch: +41 (0)71 220 90 64."
		},
		urlNotValidAnymore: {
			id: "address.urlNotValidAnymore",
			defaultMessage: "Diese URL ist nicht mehr gültig..."
		},
		invoiceXisNotAvailableRightNow: {
			id: "address.invoiceXisNotAvailableRightNow",
			defaultMessage: "Die Rechnung Nr. {nr} ist noch nicht verfügbar."
		},
		audioXisNotAvailableRightNow: {
			id: "treatment.audioXisNotAvailableRightNow",
			defaultMessage: "Die Analyse «{title}» ist noch nicht verfügbar. Bitte versuche es in ein paar Minuten erneut."
		},
		pleaseAccepptAGB: {
			id: "treatment.pleaseAccepptAGB",
			defaultMessage: "Du musst die allgemeinen Geschäftsbedingungen akzeptieren, um die Bestellung auszuführen."
		},
		pleaseAccepptRevocation: {
			id: "treatment.pleaseAccepptRevocation",
			defaultMessage: "Du musst das Widerrufsrecht akzeptieren, um die Bestellung auszuführen."
		},
		pleaseChoosePaymentMethod: {
			id: "treatment.pleaseChoosePaymentMethod",
			defaultMessage: "Bitte wähle die gewünschte Zahlungsart."
		},
		alreadyAboRunning: {
			id: "treatment.alreadyAboRunning",
			defaultMessage: "Du hast bereits ein laufendes oder zukünftiges Fernbegleitungsabo."
		},
		alreadyAboInCart: {
			id: "treatment.alreadyAboInCart",
			defaultMessage: "Du hast bereits ein Fernbegleitungsabo für Dich im Warenkorb."
		},
		userHasAlreadyAboRunning: {
			id: "treatment.userHasAlreadyAboRunning",
			defaultMessage: "«{user}» hat bereits ein laufendes Fernbegleitungsabo."
		},
		userHasAlreadyAboInCart: {
			id: "treatment.userHasAlreadyAboInCart",
			defaultMessage: "Es ist bereits ein Fernbegleitungsabo für «{user}» im Warenkorb."
		},
		paymentMethodSuccessfullyChangedPaymentFailed: {
			id: "profil.paymentMethodSuccessfullyChangedPaymentFailed",
			defaultMessage: "Die Zahlungsart wurde erfolgreich geändert, die ausstehende Zahlung konnte jedoch nicht abgebucht werden!"
		}
	});

	const error = useSelector((state) => state.app.error);
	const dispatch = useDispatch();
	const clearError = useCallback(() => dispatch(appClearError()), [dispatch]);
	let message = "";

	if (error) {
		if (typeof error === "string" && error in messages) {
			message = <FormattedMessage {...messages[error]} />;
		} else if (error.key && error.key in messages) {
			message = <FormattedMessage {...messages[error.key]} values={error.values} />;
		}
	}

	useEffect(() => {
		return () => {
			clearError();
		};
	}, [clearError]);

	return message ? (
		<div className={`message error${marginTop ? " spaceBefore" : ""}`}>
			<div>
				<div>
					<Icon />
				</div>
			</div>
			<div>{message}</div>
		</div>
	) : null;
};

export default React.memo(Error);
