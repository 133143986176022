import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Arrow } from "../assets/images/angle-right-solid.svg";
import { ReactComponent as Add } from "../assets/images/add.svg";
import styles from "./Button.module.css";

const Button = ({ btnClass, type, action, disabled, children, to, home, add, href }) => {
    const history = useHistory();
    const classes = [styles.button];
    if (btnClass) {
        classes.push(styles[btnClass]);
    }
    if (add) {
        classes.push(styles.add);
    }
    if (to) {
        action = () => {
            history.push(to);
        };
    }

    const btnContent = [children];
    if (!home) {
        if (btnClass === "back") {
            btnContent.unshift(<Arrow key="arrow" />);
        } else {
            if (add) {
                btnContent.push(<Add key="add" />);
            } else {
                btnContent.push(<Arrow key="arrow" />);
            }
        }
    }

    if (href) {
        return (
            <form method="get" action={href} target="_blank">
                <button className={classes.join(" ")} type="submit" disabled={disabled}>
                    {btnContent}
                </button>
            </form>
        );
    } else {
        return (
            <button className={classes.join(" ")} type={type ? type : "button"} onClick={action} disabled={disabled}>
                {btnContent}
            </button>
        );
    }
};

export default Button;
