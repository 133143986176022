import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { auth, _getNewestVersion } from "../../store/actions/index";

import Error from "../../ui/Error";
import Success from "../../ui/Success";
import Button from "../../ui/Button";
import { getRoute } from "../../shared/routes";

const Login = () => {
    const version = useSelector((state) => state.app.version);
    const newestVersion = useSelector((state) => state.app.newestVersion);
    const [eMail, set_eMail] = useState("");
    const [password, setPassword] = useState("");
    const [eMailValid, set_eMailValid] = useState(false);
    const [passwordValid, set_passwordValid] = useState(false);
    const inputRef = useRef();
    const language = useSelector((state) => state.app.language);

    const dispatch = useDispatch();

    const getNewestVersion = useCallback(() => dispatch(_getNewestVersion()), [dispatch]);
    const login = (eMail, password) => dispatch(auth(eMail, password));

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    useEffect(() => {
        if (!newestVersion) {
            getNewestVersion();
        } else if (version !== newestVersion) {
            window.location.reload();
        }
    }, [version, newestVersion, getNewestVersion]);

    const changeEMail = (event) => {
        set_eMail(event.target.value);
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        set_eMailValid(pattern.test(event.target.value));
    };

    const changePassword = (event) => {
        setPassword(event.target.value);
        set_passwordValid(event.target.value.length > 7);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        login(eMail, password);
    };

    return (
        <div className="login">
            <h1>
                <FormattedMessage id="login.welcome" defaultMessage="Herzlich willkommen" />
            </h1>
            <p>
                <br />
                <FormattedMessage
                    id="login.info"
                    defaultMessage="Buche deine Fernbegleitung.{br}Mit deinem Konto hast du alles im Blick!"
                    values={{
                        br: <br />
                    }}
                />
            </p>
            <Error marginTop />
            <Success marginTop />
            <form onSubmit={onSubmit} id="login" className="spaceBefore">
                <label className="above">
                    <FormattedMessage id="login.yourEMail" defaultMessage="Deine E-Mail Adresse" />
                    <input type="text" ref={inputRef} value={eMail} onChange={changeEMail} />
                </label>
                <label className="above">
                    <FormattedMessage id="login.yourPassword" defaultMessage="Dein Passwort" />
                    <input type="password" value={password} onChange={changePassword} />
                </label>
                <div className="spaceBefore">
                    <Button btnClass="login" home type="submit" disabled={!(eMailValid && passwordValid)}>
                        <FormattedMessage id="login.login" defaultMessage="Anmelden" />
                    </Button>
                </div>
                <br />
                <Link to={getRoute("createAccount", language)}>
                    <FormattedMessage id="login.createAccount" defaultMessage="Konto erstellen" />
                </Link>
                <br />
                <Link to={getRoute("passwordForgotten", language)}>
                    <FormattedMessage id="login.passwordForgotten" defaultMessage="Passwort vergessen" />
                </Link>
            </form>
        </div>
    );
};

export default Login;
