import { defineMessages } from "react-intl";
import { getRoute } from "./routes";

export const authenticatedMenus = (intl, language) => {
	const menuNames = defineMessages({
		treatments: { id: "menu.treatments", defaultMessage: "Home" },
		activeTreatments: { id: "menu.activeTreatments", defaultMessage: "Aktive Fernbegleitungen" },
		futureTreatments: { id: "menu.futureTreatments", defaultMessage: "Zukünftige Fernbegleitungen" },
		doneTreatments: { id: "menu.doneTreatments", defaultMessage: "Abgeschlossene Fernbegleitungen" },
		audioAnalysis: { id: "menu.audioAnalysis", defaultMessage: "Analysen" },
		invoices: { id: "menu.invoices", defaultMessage: "Rechnungen" },
		account: { id: "menu.account", defaultMessage: "Konto" },
		logout: { id: "menu.logout", defaultMessage: "Abmelden" },
		editAddress: { id: "menu.editAddress", defaultMessage: "Adresse bearbeiten" },
		changePassword: { id: "menu.changePassword", defaultMessage: "Passwort ändern" },
		changeEMail: { id: "menu.changeEMail", defaultMessage: "E-Mail Adresse ändern" },
		moneyBackWarranty: { id: "menu.moneyBackWarranty", defaultMessage: "30 Tage Geld zurück Garantie" },
		paymentTypeAbos: { id: "menu.paymentTypeAbos", defaultMessage: "Zahlungsart Abos" },
		order: { id: "menu.order", defaultMessage: "Bestellung" },
		choosePaymentMethod: { id: "menu.choosePaymentMethod", defaultMessage: "Zahlungsart wählen" },
		treatmentBook: { id: "treatment.remoteTreatmentAbo", defaultMessage: "Fernbegleitungsabo" }
	});

	return [
		{
			url: getRoute("activeTreatments", language),
			name: intl.formatMessage(menuNames.treatments),
			breadcrump: intl.formatMessage(menuNames.activeTreatments),
			component: "Home/Home",
			default: true,
			menus: [
				{
					url: "/:uuid",
					name: "",
					component: "Home/Treatment/Treatment"
				}
			]
		},
		{
			url: getRoute("futureTreatments", language),
			name: "",
			component: "Home/Home",
			breadcrump: intl.formatMessage(menuNames.futureTreatments),
			menus: [
				{
					url: "/:uuid",
					name: "",
					component: "Home/Treatment/Treatment"
				}
			]
		},
		{
			url: getRoute("terminatedTreatments", language),
			name: "",
			component: "Home/Home",
			breadcrump: intl.formatMessage(menuNames.doneTreatments),
			menus: [
				{
					url: "/:uuid",
					name: "",
					component: "Home/Treatment/Treatment"
				}
			]
		},
		{
			url: getRoute("order", language),
			name: "",
			menus: [
				{
					url: getRoute("remoteTreatmentInSubscription", language),
					name: "Fernbehandlungsabo",
					component: "Bestellen/FernbehandlungsAbo/FernbehandlungsAbo"
				}
			]
		},
		{
			url: getRoute("account", language),
			name: intl.formatMessage(menuNames.account),
			component: "Konto/Konto",
			menus: [
				{
					url: getRoute("editAddress", language),
					name: intl.formatMessage(menuNames.editAddress),
					component: "Konto/AdresseBearbeiten/AdresseBearbeiten"
				},
				{
					url: getRoute("password", language),
					name: intl.formatMessage(menuNames.changePassword),
					component: "Konto/PasswortAendern/PasswortAendern"
				},
				{
					url: getRoute("eMail", language),
					name: intl.formatMessage(menuNames.changeEMail),
					component: "Konto/EMailAdresseWechseln/EMailAdresseWechseln"
				},
				{
					url: getRoute("moneyBackGuarantee", language),
					name: intl.formatMessage(menuNames.moneyBackWarranty),
					component: "Konto/MoneyBackWarranty/MoneyBackWarranty"
				},
				{
					url: getRoute("paymentMethodSubscriptions", language),
					name: intl.formatMessage(menuNames.paymentTypeAbos),
					component: "Konto/PaymentTypeAbos/PaymentTypeAbos",
					menus: [
						{
							url: getRoute("paymentMethod", language),
							name: intl.formatMessage(menuNames.choosePaymentMethod),
							component: "Konto/PaymentTypeAbos/Zahlungsart/Zahlungsart",
							menus: [
								{
									name: "",
									url: getRoute("complete", language),
									component: "Konto/PaymentTypeAbos/Zahlungsart/Abschliessen/Abschliessen"
								}
							]
						}
					]
				},
				{
					url: getRoute("invoices", language),
					name: intl.formatMessage(menuNames.invoices),
					component: "Konto/Rechnungen/Rechnungen"
				},
				{
					url: getRoute("delete", language),
					name: "",
					component: "Konto/Loeschen/Loeschen"
				}
			]
		},
		{
			url: getRoute("logout", language),
			name: intl.formatMessage(menuNames.logout),
			component: "Login/Logout/Logout"
		},
		{
			url: getRoute("orderOverview", language),
			name: "",
			component: "Bestellung/Bestellung",
			breadcrump: intl.formatMessage(menuNames.order),
			menus: [
				{
					url: getRoute("paymentMethod", language),
					name: "Zahlungsart",
					component: "Bestellung/Zahlungsart/Zahlungsart"
				},
				{
					url: getRoute("done", language),
					name: "Fertig",
					component: "Bestellung/Fertig/Fertig"
				}
			]
		},
		{
			url: getRoute("completeOrder", language),
			name: "",
			component: "Bestellung/Fertig/Fertig"
		},
		{
			url: getRoute("support", language),
			name: "",
			component: "Kundensupport/Kundensupport"
		},
		{
			url: getRoute("deletionConcept", language),
			name: "",
			component: "Loeschkonzept/Loeschkonzept"
		}
	];
};
