export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_CHECK_EMAIL_SENT = "AUTH_CHECK_EMAIL_SENT";
export const AUTH_UPDATE_TOKENS = "AUTH_UPDATE_TOKENS";
export const AUTH_RESTART_SESSION = "AUTH_RESTART_SESSION";
export const AUTH_STOP_SESSION_CHECKER = "AUTH_STOP_SESSION_CHECKER";
export const AUTH_CHECK_SESSION = "AUTH_CHECK_SESSION";
export const AUTH_SET_SESSIONINTERVAL = "AUTH_SET_SESSIONINTERVAL";
export const AUTH_AUTOSIGNUP_TRIED = "AUTH_AUTOSIGNUP_TRIED";
export const AUTH_SAVE_UNIQUE_KEY = "AUTH_SAVE_UNIQUE_KEY";
export const AUTH_SAVE_COUNTRY_DATA = "AUTH_SAVE_COUNTRY_DATA";

export const APP_SET_ERROR = "APP_SET_ERROR";
export const APP_CLEAR_FIELD_ERROR = "APP_CLEAR_FIELD_ERROR";
export const APP_SET_SUCCESS = "APP_SET_SUCCESS";
export const APP_SET_NOTE = "APP_SET_NOTE";
export const APP_CLEAR_ERROR = "APP_CLEAR_ERROR";
export const APP_CLEAR_SUCCESS = "APP_CLEAR_SUCCESS";
export const APP_CLEAR_NOTE = "APP_CLEAR_NOTE";
export const APP_START_LOADING = "APP_START_LOADING";
export const APP_STOP_LOADING = "APP_STOP_LOADING";
export const APP_SET_REDIRECT = "APP_SET_REDIRECT";
export const APP_SET_BREADCRUMP = "APP_SET_BREADCRUMP";
export const APP_SET_LANGUAGE = "APP_SET_LANGUAGE";
export const APP_OVERRIDE_SERVICEMODE = "APP_OVERRIDE_SERVICEMODE";
export const APP_STORE_VERSION = "APP_STORE_VERSION";

export const USER_GET_DATA = "USER_GET_DATA";
export const USER_SAVE_ADDRESS = "USER_SAVE_ADDRESS";
export const USER_CHANGE_CURRENCY = "USER_CHANGE_CURRENCY";
export const USER_PREPARE_SOUND = "USER_PREPARE_SOUND";
export const USER_STOP_SOUND = "USER_STOP_SOUND";
export const USER_ADD_SHORT_ANALYSIS = "USER_ADD_SHORT_ANALYSIS";
export const USER_ADD_SYMPTOM_FOCUSING = "USER_ADD_SYMPTOM_FOCUSING";
export const USER_DELETE_SYMPTOM_FOCUSING = "USER_DELETE_SYMPTOM_FOCUSING";
export const USER_CLEAR_DATA = "USER_CLEAR_DATA";
export const USER_SAVE_COUNTRY = "USER_SAVE_COUNTRY";
export const USER_ADD_TO_BE_TREATED = "USER_ADD_TO_BE_TREATED";
export const USER_REMOVE_TO_BE_TREATED = "USER_REMOVE_TO_BE_TREATED";
export const USER_ADD_TREATMENT_TO_CART = "USER_ADD_TREATMENT_TO_CART";
export const USER_ADD_ENERGY_ANALYSIS_TO_CART = "USER_ADD_ENERGY_ANALYSIS_TO_CART";
export const USER_REMOVE_PRODUCT_FROM_CART = "USER_REMOVE_PRODUCT_FROM_CART";
export const USER_TERMINATE_TREATMENT = "USER_TERMINATE_TREATMENT";
export const USER_UNDO_TERMINATE_TREATMENT = "USER_UNDO_TERMINATE_TREATMENT";
export const USER_CHANGE_TREATMENT_REASON = "USER_CHANGE_TREATMENT_REASON";
export const USER_MONEY_BACK_WARRANTY = "USER_MONEY_BACK_WARRANTY";
export const USER_SET_REDIRECT_TO_PAY = "USER_SET_REDIRECT_TO_PAY";
export const USER_CLEAR_CART = "USER_CLEAR_CART";
export const USER_ORDER_TREATMENTS = "USER_ORDER_TREATMENTS";
export const USER_UPDATE_EMAIL = "USER_UPDATE_EMAIL";
export const USER_DELETE_ACCOUNT = "USER_DELETE_ACCOUNT";
export const STORE_PAYMENT_DATA = "STORE_PAYMENT_DATA";
export const CLEAR_PAYMENT_DATA = "CLEAR_PAYMENT_DATA";
export const SET_PAYMENT_CHANGE_ABOS = "SET_PAYMENT_CHANGE_ABOS";
export const CHANGE_PAYMENT_TO_BILL = "CHANGE_PAYMENT_TO_BILL";
export const USER_STORE_STRIPE_ID = "USER_STORE_STRIPE_ID";
export const USER_STORE_PAYMENT_SERVICE_PROVIDER_STATE = "USER_STORE_PAYMENT_SERVICE_PROVIDER_STATE";
