import * as actionTypes from "./actionTypes";
import axios from "../../rbAxios";

export const appSetError = (error, fieldErrors, clearErrors = false) => {
	return {
		type: actionTypes.APP_SET_ERROR,
		error: error,
		fieldErrors: fieldErrors,
		clearErrors: clearErrors
	};
};

export const appClearFieldError = (field) => {
	return {
		type: actionTypes.APP_CLEAR_FIELD_ERROR,
		field: field
	};
};

export const appClearError = () => {
	return {
		type: actionTypes.APP_CLEAR_ERROR
	};
};

export const appSetSuccess = (success) => {
	return {
		type: actionTypes.APP_SET_SUCCESS,
		success: success
	};
};

export const appClearSuccess = () => {
	return {
		type: actionTypes.APP_CLEAR_SUCCESS
	};
};

export const appSetNote = (note) => {
	return {
		type: actionTypes.APP_SET_NOTE,
		note: note
	};
};

export const appClearNote = () => {
	return {
		type: actionTypes.APP_CLEAR_NOTE
	};
};

export const appStartLoading = () => {
	return {
		type: actionTypes.APP_START_LOADING
	};
};

export const appStopLoading = () => {
	return {
		type: actionTypes.APP_STOP_LOADING
	};
};

export const appSetRedirect = (redirect) => {
	return {
		type: actionTypes.APP_SET_REDIRECT,
		redirect: redirect
	};
};

export const appSetBreadcrump = (breadcrump) => {
	return {
		type: actionTypes.APP_SET_BREADCRUMP,
		breadcrump: breadcrump
	};
};

export const appSetLanguage = (language) => {
	return {
		type: actionTypes.APP_SET_LANGUAGE,
		language: language
	};
};

export const overrideServiceMode = () => {
	return {
		type: actionTypes.APP_OVERRIDE_SERVICEMODE
	};
};

const storeVersion = (version) => {
	return {
		type: actionTypes.APP_STORE_VERSION,
		version: version
	};
};

export const _getNewestVersion = () => {
	return (dispatch) => {
		dispatch(appStartLoading());
		axios
			.get("/api/version")
			.then((response) => {
				dispatch(storeVersion(response.data.version));
				dispatch(appStopLoading());
			})
			.catch((err) => {
				dispatch(appStopLoading());
			});
	};
};
