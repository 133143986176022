import timeDiff from "./timeDiff";
import { defineMessages } from "react-intl";
//import config from "react-global-configuration";

import Postfinance from "../assets/images/pay/postfinance.png";
import Twint from "../assets/images/pay/twint.png";
import Sepa from "../assets/images/pay/sepa.png";
import Visa from "../assets/images/pay/visa.png";
import Mastercard from "../assets/images/pay/mastercard.png";
import AmericanExpress from "../assets/images/pay/american-express.png";

export const Money = ({ value, currency }) => {
	switch (currency) {
		case "CHF":
			return "CHF " + value.toFixed(2);

		case "EUR":
			return "€ " + value.toFixed(2).replace(".", ",");

		case "GBP":
			return "£" + value.toFixed(2);

		case "USD":
			return "$" + value.toFixed(2);

		default:
			return "";
	}
};

export const Currency = ({ currency }) => {
	switch (currency) {
		case "CHF":
			return "CHF";

		case "EUR":
			return "€";

		case "GBP":
			return "£";

		case "USD":
			return "$";

		default:
			return "";
	}
};

export const ShortMoney = ({ value, currency }) => {
	switch (currency) {
		case "CHF":
			return value.toFixed(0) + ".–";

		case "EUR":
			return value.toFixed(0) + ",–";

		case "GBP":
			return value.toFixed(0) + ".–";

		case "USD":
			return value.toFixed(0) + ".–";

		default:
			return "";
	}
};

export const checkValidity = (value, rules) => {
	let isValid = true;

	if (rules.required) {
		isValid = value.trim() !== "";
	}

	if (rules.minLength) {
		isValid = isValid && value.length >= rules.minLength;
	}

	if (rules.maxLength) {
		isValid = isValid && value.length <= rules.maxLength;
	}

	if (rules.isEmail) {
		const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
		isValid = pattern.test(value) && isValid;
	}

	if (rules.isNumeric) {
		const pattern = /^\d+$/;
		isValid = pattern.test(value) && isValid;
	}

	return isValid;
};

export const getEndDateOfMonthTreatment = (startDate) => {
	const start = getDateFromFMDate(startDate);
	const end = new Date(start.getTime());
	const nextMonthNr = end.getMonth() + 1;
	end.setMonth(nextMonthNr);
	if (end.getMonth() !== nextMonthNr) {
		end.setDate(0);
	}
	return toFMDate(end);
};

export const getEndDateOfAboTreatment = (startDate) => {
	const start = getDateFromFMDate(startDate);
	const end = new Date(start.getTime());
	const nextMonthNr = end.getMonth() + 5;
	end.setMonth(nextMonthNr);
	if (end.getMonth() !== nextMonthNr) {
		end.setDate(0);
	}
	return toFMDate(end);
};

export const getDateFromFMDate = (fmDate) => {
	const [month, day, year] = fmDate.split("/");
	return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

export const toFMDate = (date) => {
	return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
};

export const today = () => {
	const today = new Date();
	today.setTime(today.getTime() - timeDiff.timeDiff);
	today.setHours(0, 0, 0, 0);
	return today;
};

export const remoteTreatmentState = (remoteTreatment) => {
	if (getDateFromFMDate(remoteTreatment["_FB::DatumVon"]) > today()) {
		return "future";
	} else if (
		(remoteTreatment["_FB::Produktkuerzel"] === "FM" && getDateFromFMDate(remoteTreatment["_FB::DatumEnde"]) < today()) ||
		(remoteTreatment["_FB::Produktkuerzel"] === "FA" && remoteTreatment["_FB::Gekuendigt"].toLowerCase() === "ja" && getDateFromFMDate(remoteTreatment["_FB::DatumEnde"]) < today())
	) {
		return "past";
	} else {
		return "active";
	}
};

export const paymentMethods = (intl) => {
	const paymentMethodNames = defineMessages({
		invoice: { id: "paymentMethod.invoice", defaultMessage: "Rechnung" },
		creditCard: { id: "paymentMethod.creditCard", defaultMessage: "Kreditkarte" }
	});

	return [
		{
			name: intl.formatMessage(paymentMethodNames.invoice),
			value: "bill",
			currency: ["CHF", "EUR"],
			displayInCheckout: true
		},
		{
			name: "Postfinance",
			value: "PFC",
			currency: ["CHF"],
			img: [Postfinance],
			displayInCheckout: true
		},
		{
			name: "Twint",
			value: "TWI",
			currency: ["CHF"],
			img: [Twint],
			displayInCheckout: true
		},
		{
			name: "SEPA Lastschriftverfahren",
			value: "ELV",
			currency: ["EUR"],
			img: [Sepa],
			displayInCheckout: true
		},
		{
			name: intl.formatMessage(paymentMethodNames.creditCard),
			value: "CARD",
			currency: ["CHF", "EUR", "GBP", "USD"],
			img: [Visa, Mastercard, AmericanExpress],
			displayInCheckout: true
		},
		{
			name: "Visa",
			value: "VISA",
			currency: ["CHF", "EUR", "GBP", "USD"],
			img: [Visa],
			displayInCheckout: false
		},
		{
			name: "Mastercard",
			value: "MASTERCARD",
			currency: ["CHF", "EUR", "GBP", "USD"],
			img: [Mastercard],
			displayInCheckout: false
		},
		{
			name: "American Express",
			value: "AMEX",
			currency: ["CHF", "EUR", "GBP", "USD"],
			img: [AmericanExpress],
			displayInCheckout: false
		}
	];
};

export const onlyUnique = (value, index, self) => {
	return self.indexOf(value) === index;
};

export const canOrderAnalysis = (cart, users, uuidUser = null) => {
	const user = uuidUser ? users.find((entry) => entry["_ZB::UUIDZuBehandelnder"] === uuidUser) : users;
	if (cart.find((entry) => entry.productCode === "audio" && entry.toBeTreatedUUID === user["_ZB::UUIDZuBehandelnder"])) {
		return false;
	}
	if (user && user["_ZB::DatNextAnalyse"]) {
		const nextAnalysis = getDateFromFMDate(user["_ZB::DatNextAnalyse"]);
		const now = today();
		//now.setDate(now.getDate() + config.get("daysToNextAnalysis"));
		return nextAnalysis.getTime() <= now.getTime();
	} else {
		return true;
	}
};

export const hasAboInCart = (cart, uuidUser) => {
	return cart.filter((entry) => entry.productCode === "abo").find((entry) => entry.toBeTreatedUUID === uuidUser) !== undefined;
};

export const hasBookedAbos = (treatments, uuidUser, exclude = null) => {
	const userAbos = treatments.filter((entry) => entry["_FB::UUIDzuBehandelnder"] === uuidUser && entry["_FB::Produktkuerzel"] === "FA" && (entry["_FB::Gekuendigt"] === "" || getDateFromFMDate(entry["_FB::DatumVon"]) > today()) && (!exclude || exclude !== entry["_FB::UUIDFernbehandlung"]));
	return userAbos.length > 0;
};
