import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { confirmEMailKey } from "../../store/actions/index";
import { getRoute } from "../../shared/routes";

const messages = defineMessages({
	serverFailMailSubject: { id: "createAccount.serverFailMailSubject", defaultMessage: "Technische Probleme im Kundenportal von Rico Brunner behoben" },
	serverFailMailTextBeforeLink: {
		id: "createAccount.serverFailMailTextBeforeLink",
		defaultMessage: "Guten Tag\n\nDu wolltest dich bei uns registrieren als wir technische Probleme mit dem Server hatten. Dafür entschuldigen wir uns.\n\nUm deine Registration des Kontos abzuschliessen, klicke bitte auf folgenden Link:\n"
	},
	serverFailMailTextAfterLink: {
		id: "createAccount.serverFailMailTextAfterLink",
		defaultMessage: "Dieser Link ist 1 Stunde gültig.\n\nFüge diese Adresse bitte zu deinen vertrauenswürdigen E-Mail Adressen hinzu, damit sie nicht im Spam landet.\n\nHerzliche Grüsse\nTeam Rico Brunner"
	}
});

const CreateAccount2 = (props) => {
	const key = props.match.params.key;
	const language = useSelector((state) => state.app.language);
	const dispatch = useDispatch();
	const confirmEMail = useCallback(
		(key, serverFailMailSubject, serverFailMailTextBeforeLink, serverFailMailTextAfterLink, redirect, redirectOnError) => dispatch(confirmEMailKey(key, serverFailMailSubject, serverFailMailTextBeforeLink, serverFailMailTextAfterLink, redirect, redirectOnError)),
		[dispatch]
	);

	const intl = useIntl();

	useEffect(() => {
		confirmEMail(key, intl.formatMessage(messages.serverFailMailSubject), intl.formatMessage(messages.serverFailMailTextBeforeLink), intl.formatMessage(messages.serverFailMailTextAfterLink), getRoute("finishCreateAccount", language), getRoute("createAccount", language));
	}, [confirmEMail, key, intl, language]);

	return (
		<div>
			<h1>
				<FormattedMessage id="createAccount.title" />
			</h1>
		</div>
	);
};

export default CreateAccount2;
