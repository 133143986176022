import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const MenuPoint = ({ to, children, exactPath = false }) => {
	const location = useLocation();
	let mainURL = location.pathname;
	if (mainURL.split("/").length > 2) {
		mainURL = "/" + mainURL.split("/")[1];
	}

	const className = exactPath ? (mainURL === to ? "active" : "") : to.indexOf(mainURL) === 0 ? "active" : "";

	return (
		<li>
			<Link className={className} to={to}>
				{children}
			</Link>
		</li>
	);
};

export default MenuPoint;
