const routes = [
    {
        key: "createAccount",
        de: "/konto_erstellen",
        en: "/create_account",
        unautenticateted: true,
        autenticateted: false
    },
    {
        key: "finishCreateAccount",
        de: "/konto_fertigstellen",
        en: "/finish_create_account",
        unautenticateted: true,
        autenticateted: false
    },
    {
        key: "passwordForgotten",
        de: "/passwort_vergessen",
        en: "/password_forgotten",
        unautenticateted: true,
        autenticateted: false
    },
    {
        key: "newPassword",
        de: "/neues_passwort",
        en: "/new_password",
        unautenticateted: true,
        autenticateted: false
    },
    {
        key: "confirmEMail",
        de: "/email_bestaetigen",
        en: "/confirm_email",
        unautenticateted: true,
        autenticateted: false
    },
    {
        key: "error",
        de: "/fehler",
        en: "/error",
        unautenticateted: true,
        autenticateted: true
    },
    {
        key: "thanks",
        de: "/danke",
        en: "/thanks",
        unautenticateted: true,
        autenticateted: true
    },
    {
        key: "agb",
        de: "/agb",
        en: "/gtc",
        unautenticateted: true,
        autenticateted: true
    },
    {
        key: "privacyStatement",
        de: "/datenschutzerklaerung",
        en: "/privacy_statement",
        unautenticateted: true,
        autenticateted: true
    },
    {
        key: "imprint",
        de: "/impressum",
        en: "/imprint",
        unautenticateted: true,
        autenticateted: true
    },
    {
        key: "activeTreatments",
        de: "/fernbegleitungen/aktiv",
        en: "/remote_treatments/active",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "futureTreatments",
        de: "/fernbegleitungen/zukuenftig",
        en: "/remote_treatments/future",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "terminatedTreatments",
        de: "/fernbegleitungen/abgeschlossen",
        en: "/remote_treatments/terminated",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "order",
        de: "/bestellen",
        en: "/order",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "monthlyRemoteTreatment",
        de: "/monatsfernbegleitung",
        en: "/monthly_remote_treatment",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "remoteTreatmentInSubscription",
        de: "/fernbegleitungsabo",
        en: "/remote_treatment_in_subscription",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "account",
        de: "/konto",
        en: "/account",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "editAddress",
        de: "/adresse_bearbeiten",
        en: "/edit_address",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "password",
        de: "/passwort",
        en: "/password",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "eMail",
        de: "/email",
        en: "/email",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "moneyBackGuarantee",
        de: "/geld_zurueck_garantie",
        en: "/money_back_guarantee",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "paymentMethodSubscriptions",
        de: "/zahlungsart_abos",
        en: "/payment_method_subscriptions",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "paymentMethod",
        de: "/zahlungsart",
        en: "/payment_method",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "complete",
        de: "/abschliessen",
        en: "/complete",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "invoices",
        de: "/rechnungen",
        en: "/invoices",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "delete",
        de: "/loeschen",
        en: "/delete",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "logout",
        de: "/logout",
        en: "/logout",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "orderOverview",
        de: "/bestellung",
        en: "/order_overview",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "done",
        de: "/fertig",
        en: "/done",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "completeOrder",
        de: "/bestellung_abschliessen",
        en: "/complete_order",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "support",
        de: "/support",
        en: "/support",
        unautenticateted: false,
        autenticateted: true
    },
    {
        key: "deletionConcept",
        de: "/loeschkonzept",
        en: "/deletion_concept",
        unautenticateted: false,
        autenticateted: true
    }
];

export const getRoute = (key, language) => {
    const route = routes.find((entry) => entry.key === key);
    return route[language];
};

export const getLanguageOfPath = (path) => {
    if (routes.find((entry) => entry.de === path)) {
        return "de";
    } else if (routes.find((entry) => entry.en === path)) {
        return "en";
    } else {
        return null;
    }
};
