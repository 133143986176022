import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import Button from "../../ui/Button";
import Error from "../../ui/Error";

import { reAuth, logout, stopSessionChecker, appClearError } from "../../store/actions/index";

const AskPassword = () => {
	const dispatch = useDispatch();
	const dispatchReAuth = (password, token) => dispatch(reAuth(password, token));
	const dispatchLogout = () => dispatch(logout());
	const dispatchStopSessionChecker = useCallback(() => dispatch(stopSessionChecker()), [dispatch]);
	const dispatchAppClearError = useCallback(() => dispatch(appClearError()), [dispatch]);
	const [password, setPassword] = useState("");
	const token = localStorage.getItem("token");
	const intl = useIntl();

	const messages = defineMessages({
		yourPassword: { id: "app.yourPassword", defaultMessage: "Dein Passwort" }
	});

	useEffect(() => {
		localStorage.removeItem("token");
		dispatchStopSessionChecker();
		dispatchAppClearError();
	}, [dispatchStopSessionChecker, dispatchAppClearError]);

	const tryReAuth = (event) => {
		event.preventDefault();
		dispatchReAuth(password, token);
	};

	return (
		<div className="window">
			<div className="ask">
				<div className="instruction">
					<FormattedMessage id="app.sessionExpired" defaultMessage="Deine Session ist abgelaufen. Bitte gib dein Passwort ein um diese zu reaktiveren:" />
				</div>
				<Error />
				<div className="content">
					<form onSubmit={tryReAuth}>
						<input type="password" value={password} onChange={(event) => setPassword(event.target.value)} placeholder={intl.formatMessage(messages.yourPassword)} />
						<div className="bottomButtons spaceBefore">
							<Button action={() => dispatchLogout()}>
								<FormattedMessage id="app.cancel" />
							</Button>
							<Button type="submit" btnClass="default">
								<FormattedMessage id="app.send" defaultMessage="Absenden" />
							</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AskPassword;
