import React from "react";
import { FormattedMessage } from "react-intl";

const Impressum = () => {
    return (
        <div>
            <h1>
                <FormattedMessage id="impressum.title" defaultMessage="Impressum" />
            </h1>
            <p>
                <FormattedMessage
                    id="impressum.contact"
                    defaultMessage="<strong>Kontakt</strong>{br}Rico Brunner AG{br}Zürcher Strasse 170{br}9014 St. Gallen{br}Schweiz"
                    values={{
                        br: <br />,
                        strong: (chunks) => <strong>{chunks}</strong>
                    }}
                />
            </p>
            <p>
                <FormattedMessage
                    id="impressum.contactData"
                    defaultMessage="Telefon: +41 71 220 90 64{br}E-Mail: <a>info@rico-brunner.com</a>"
                    values={{
                        br: <br />,
                        a: (chunks) => <a href="mailto:info@rico-brunner.com">{chunks}</a>
                    }}
                />
            </p>
            <p>
                <FormattedMessage
                    id="impressum.represented"
                    defaultMessage="<strong>Vertreten durch den Geschäftsführer</strong>{br}Rico Brunner"
                    values={{
                        br: <br />,
                        strong: (chunks) => <strong>{chunks}</strong>
                    }}
                />
            </p>
            <p>
                <FormattedMessage
                    id="impressum.commercialRegister"
                    defaultMessage="<strong>Handelsregister</strong>{br}Amt für Handelsregister und Notariate{br}Davidstrasse 27{br}Postfach{br}CH-9001 St. Gallen{br}Registernummer: CHE-445.215.384"
                    values={{
                        br: <br />,
                        strong: (chunks) => <strong>{chunks}</strong>
                    }}
                />
            </p>
            <p>
                <FormattedMessage
                    id="impressum.concept"
                    defaultMessage="<strong>Konzeption und Umsetzung</strong>{br}Database Designs{br}Brigitta Hausherr{br}Haggenhaldenweg 3{br}CH-9014 St. Gallen{br}Schweiz"
                    values={{
                        br: <br />,
                        strong: (chunks) => <strong>{chunks}</strong>
                    }}
                />
            </p>
            <p>
                <FormattedMessage
                    id="impressum.conceptContactData"
                    defaultMessage="Telefon: +41 71 278 87 18{br}E-Mail: <a>brigitta.hausherr@database-designs.ch</a>"
                    values={{
                        br: <br />,
                        a: (chunks) => <a href="mailto:brigitta.hausherr@database-designs.ch">{chunks}</a>
                    }}
                />
            </p>
        </div>
    );
};

export default Impressum;
