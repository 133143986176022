export const newAddressFields = () => ({
	uuidCustomer: {
		fmName: "UUIDKunde",
		value: "",
		required: false
	},
	eMail: {
		fmName: "eMail",
		value: "",
		required: false
	},
	gender: {
		fmName: "Geschlecht",
		value: "",
		required: true,
		error: "genderError"
	},
	firstname: {
		fmName: "Vorname",
		value: "",
		required: true,
		error: "firstnameError"
	},
	lastname: {
		fmName: "Name",
		value: "",
		required: true,
		error: "lastnameError"
	},
	street: {
		fmName: "Strasse",
		value: "",
		required: true,
		error: "streetError"
	},
	streetAddition: {
		fmName: "StrasseZusatz",
		value: "",
		required: false
	},
	zip: {
		fmName: "PLZ",
		value: "",
		required: true,
		error: "zipError"
	},
	city: {
		fmName: "Ort",
		value: "",
		required: true,
		error: "cityError"
	},
	country: {
		fmName: "Land",
		value: "",
		required: true,
		error: "countryError"
	},
	phonePrivate: {
		fmName: "TelP",
		value: "",
		required: false
	},
	phoneBusiness: {
		fmName: "TelG",
		value: "",
		required: false
	},
	mobile: {
		fmName: "TelMobile",
		value: "",
		required: false
	},
	language: {
		fmName: "Sprache",
		value: "",
		required: true
	},
	audioLanguage: {
		fmName: "Audiosprache",
		value: "",
		required: true,
		error: "audioLanguageError"
	},
	password: {
		value: "",
		required: true
	},
	password2: {
		value: "",
		required: true
	},
	currency: {
		fmName: "Waehrung",
		value: ""
	}
});
