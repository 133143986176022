import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { defineMessages, FormattedMessage } from "react-intl";

import { ReactComponent as Icon } from "../assets/images/success.svg";

import { appClearSuccess } from "../store/actions/index";

const Success = ({ marginTop, marginBottom, successKey, location }) => {
	const messages = defineMessages({
		createAccountStep1Success: {
			id: "createAccount.step1Success",
			defaultMessage: "Du erhälst in den nächsten Minuten eine E-Mail, mit welcher du den Registrationsprozess abschliessen kannst. Falls du keine E-Mail bekommen hast, prüfe bitte deinen Spamordner und füge unsere E-Mailadresse zu deinen sicheren Absendern hinzu."
		},
		createAccountSuccessfull: {
			id: "createAccount.successfull",
			defaultMessage: "Dein Konto wurde erfolgreich erstellt!"
		},
		loginSuccessfully: {
			id: "login.loginSuccessfully",
			defaultMessage: "Herzlich willkommen {firstname} {lastname}!"
		},
		addressSavedSuccessfully: {
			id: "profil.addressSavedSuccessfully",
			defaultMessage: "Deine Adresse wurde erfolgreich gespeichert!"
		},
		symptomFocusingCreatedSuccessfully: {
			id: "treatment.symptomFocusingCreatedSuccessfully",
			defaultMessage: "Die Tagesbegleitung wurde erfolgreich erstellt!"
		},
		symptomFocusingesCreatedSuccessfully: {
			id: "treatment.symptomFocusingesCreatedSuccessfully",
			defaultMessage: "Die Tagesbegleitungen wurde erfolgreich erstellt!"
		},
		symptomFocusingDeletedSuccessfully: {
			id: "treatment.symptomFocusingDeletedSuccessfully",
			defaultMessage: "Die Tagesbegleitung wurde erfolgreich gelöscht!"
		},
		shortAnalysisOrderedSuccessfully: {
			id: "treatment.shortAnalysisOrderedSuccessfully",
			defaultMessage: "Die Zwischenanalyse wurde erfolgreich angefordert!"
		},
		passwordChangedSuccessfully: {
			id: "address.passwordChangedSuccessfully",
			defaultMessage: "Das Password wurde erfolgreich geändert!"
		},
		orderSubmittedSuccessfully: {
			id: "treatment.orderSubmittedSuccessfully",
			defaultMessage: "Vielen Dank für deine Bestellung. In Kürze erhälst du eine Bestätigung per E-Mail."
		},
		orderAndPaymentSuccessfully: {
			id: "treatment.orderAndPaymentSuccessfully",
			defaultMessage: "Deine Bestellung wurde erfolgreich übermittelt, vielen Dank für die Zahlung!"
		},
		passwordForgottenEMailSentToXY: {
			id: "login.passwordForgottenEMailSentToXY",
			defaultMessage: "Wir haben dir eine E-Mail an die Adresse «{eMail}» mit Instruktionen zur Erstellung eines neuen Passwortes gesendet."
		},
		eMailChangeMailSent: {
			id: "address.eMailChangeMailSent",
			defaultMessage: "Wir haben dir eine Nachricht an deine neue E-Mail Adresse gesandt. Bitte klicke innerhalb einer Stunde auf den Bestätigungslink, um deine neue E-Mail Adresse zu aktivieren."
		},
		eMailChangeSuccessfully: {
			id: "address.eMailChangeSuccessfully",
			defaultMessage: "Deine neue E-Mail Adresse ist nun aktiv!"
		},
		treatmentTerminatedSuccessfully: {
			id: "treatment.treatmentTerminatedSuccessfully",
			defaultMessage: "Dein Fernbegleitungsabo wurde per {date} gekündigt! Du erhälst in Kürze eine Bestätigung per E-Mail."
		},
		undoTerminatedTreatmentSuccessfully: {
			id: "treatment.undoTerminatedTreatmentSuccessfully",
			defaultMessage: "Die Kündigung deines Fernbegleitungsabo wurde erfolgreich widerrufen!"
		},
		moneyBackWarrantySuccessfully: {
			id: "treatment.moneyBackWarrantySuccessfully",
			defaultMessage: "Dein Anspruch auf die «30 Tage Geld zurück Garantie» wurde ans Sekretariat der Rico Brunner AG weitergeleitet und wird schnellstmöglich bearbeitet."
		},
		supportRequestSuccessfullySent: {
			id: "customerSupport.supportRequestSuccessfullySent",
			defaultMessage: "Vielen herzlichen Dank für deine Anfrage. Wir sind sehr bemüht, dir diese während den Bürozeiten innerhalb der nächsten 48 Stunden zu beantworten."
		},
		paymentMethodSuccessfullyChanged: {
			id: "profil.paymentMethodSuccessfullyChanged",
			defaultMessage: "Die Zahlungsart wurde erfolgreich geändert!"
		},
		paymentMethodSuccessfullyChangedPaymentDone: {
			id: "profil.paymentMethodSuccessfullyChangedPaymentDone",
			defaultMessage: "Die Zahlungsart wurde erfolgreich geändert, die ausstehende Zahlung wurde abgebucht!"
		}
	});

	let success = useSelector((state) => state.app.success);
	const dispatch = useDispatch();
	const clearSuccess = useCallback(() => dispatch(appClearSuccess()), [dispatch]);
	let message = "";

	if (success) {
		if (typeof success !== "object" || (typeof success === "object" && success.location === location)) {
			if (successKey) {
				success = successKey;
			}
			if (success) {
				if (typeof success === "string" && success in messages) {
					message = <FormattedMessage {...messages[success]} />;
				} else if (success.location) {
					message = <FormattedMessage {...messages[success.message]} />;
				} else if (success.key && success.key in messages) {
					message = <FormattedMessage {...messages[success.key]} values={success.values} />;
				}
			}
		}
	}

	useEffect(() => {
		return () => {
			if (message) {
				clearSuccess();
			}
		};
	}, [message, clearSuccess]);

	return (
		message && (
			<div className={`message success${marginTop ? " spaceBefore" : ""}${marginBottom ? " spaceAfter" : ""}`}>
				<div>
					<div>
						<Icon />
					</div>
				</div>
				<div>{message}</div>
			</div>
		)
	);
};

export default React.memo(Success);
