import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { authenticatedMenus } from "../../shared/structure";

const Back = () => {
	const language = useSelector((state) => state.app.language);
	const intl = useIntl();
	const menus = authenticatedMenus(intl, language);
	const location = useLocation();

	let menuName = "";
	let backURL = "";

	menus.forEach((entry) => {
		if (entry.url === location.pathname) {
			return false;
		} else if (location.pathname.indexOf(entry.url) === 0 && location.pathname.split("/")[1] === entry.url.split("/")[1]) {
			menuName = entry.breadcrump ? entry.breadcrump : entry.name;
			backURL = entry.url;
			if (entry.menus) {
				entry.menus.forEach((subEntry) => {
					if (entry.url + subEntry.url === location.pathname) {
						return false;
					} else if (location.pathname.indexOf(entry.url + subEntry.url) === 0) {
						menuName = subEntry.name;
						backURL = entry.url + subEntry.url;
					}
				});
			}
		}
	});

	if (menuName) {
		return (
			<div id="back">
				<Link to={backURL}>
					<span>{menuName}</span>
				</Link>
			</div>
		);
	}

	return null;
};

export default Back;
