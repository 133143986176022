import * as actionTypes from "../actions/actionTypes";

const sessionLength = 1000 * 60 * 15;

const inititalState = {
	token: null,
	expTime: 0,
	refreshToken: null,
	sessionTimeOut: false,
	sessionInterval: null,
	checkEMailSent: false,
	autoSignupTried: false,
	uniqueKey: null,
	countryData: null
};

const authStart = (state, action) => {
	return state;
};

const authSuccess = (state, action) => {
	return { ...state, token: action.token, refreshToken: action.refreshToken };
};

const authFail = (state, action) => {
	return { ...state, token: null };
};

const authLogout = (state, action) => {
	clearInterval(state.sessionInterval);
	return { ...state, token: null, expTime: 0, refreshToken: null, sessionTimeOut: false, sessionInterval: null, autoSignupTried: true };
};

const checkEMailSent = (state, action) => {
	return { ...state, checkEMailSent: action.success };
};

const updateTokens = (state, action) => {
	return { ...state, token: action.token, refreshToken: action.refreshToken };
};

const setSessionInterval = (state, action) => {
	const expTime = new Date().getTime() + sessionLength;
	return { ...state, sessionInterval: action.sessionInterval, expTime: expTime, sessionTimeOut: false };
};

const setAutoSignupTried = (state, action) => {
	return { ...state, autoSignupTried: true };
};

const saveUniqueKey = (state, action) => {
	return { ...state, uniqueKey: action.uniqueKey };
};

const checkSession = (state, action) => {
	return { ...state, sessionTimeOut: new Date().getTime() > state.expTime };
};

const restartSession = (state, action) => {
	const expTime = new Date().getTime() + sessionLength;
	return { ...state, expTime: expTime };
};

const stopSessionChecker = (state, action) => {
	window.clearInterval(state.sessionInterval);
	return { ...state, sessionInterval: null };
};

const saveCountryData = (state, action) => {
	return { ...state, countryData: [...action.countryData] };
};

const reducer = (state = inititalState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_START:
			return authStart(state, action);
		case actionTypes.AUTH_SUCCESS:
			return authSuccess(state, action);
		case actionTypes.AUTH_FAIL:
			return authFail(state, action);
		case actionTypes.AUTH_LOGOUT:
			return authLogout(state, action);
		case actionTypes.AUTH_CHECK_EMAIL_SENT:
			return checkEMailSent(state, action);
		case actionTypes.AUTH_UPDATE_TOKENS:
			return updateTokens(state, action);
		case actionTypes.AUTH_SET_SESSIONINTERVAL:
			return setSessionInterval(state, action);
		case actionTypes.AUTH_AUTOSIGNUP_TRIED:
			return setAutoSignupTried(state, action);
		case actionTypes.AUTH_SAVE_UNIQUE_KEY:
			return saveUniqueKey(state, action);
		case actionTypes.AUTH_CHECK_SESSION:
			return checkSession(state, action);
		case actionTypes.AUTH_RESTART_SESSION:
			return restartSession(state, action);
		case actionTypes.AUTH_STOP_SESSION_CHECKER:
			return stopSessionChecker(state, action);
		case actionTypes.AUTH_SAVE_COUNTRY_DATA:
			return saveCountryData(state, action);

		default:
			return state;
	}
};

export default reducer;
