import React from "react";
import { useSelector } from "react-redux";
import { defineMessages, useIntl } from "react-intl";

import { ReactComponent as Icon } from "../assets/images/removeSign.svg";

const FieldError = ({ field }) => {
	const fieldErrors = useSelector((state) => state.app.fieldErrors);
	const fieldError = fieldErrors[field];
	const intl = useIntl();

	const messages = defineMessages({
		currentPasswordWrong: { id: "address.currentPasswordWrong", defaultMessage: "Das aktuelle Password ist falsch!" },
		currentPasswordIncomplete: { id: "address.currentPasswordIncomplete", defaultMessage: "Bitte gib dein aktuelles Passwort ein!" },
		newPasswordIncomplete: { id: "address.newPasswordIncomplete", defaultMessage: "Dein neues Passwort ist zu unsicher!" },
		passwordConfirmationInvalid: { id: "address.passwordConfirmationInvalid", defaultMessage: "Die Bestätigung deines neuen Passwortes stimmt nicht mit deinem neuen Passwort überein!" },
		firstnameError: { id: "address.firstnameError", defaultMessage: "Bitte gib deinen Vornamen an!" },
		lastnameError: { id: "address.lastnameError", defaultMessage: "Bitte gib deinen Nachnamen an!" },
		streetError: { id: "address.streetError", defaultMessage: "Bitte gib deine Strasse an!" },
		zipError: { id: "address.zipError", defaultMessage: "Bitte gib deine Postleitzahl an!" },
		cityError: { id: "address.cityError", defaultMessage: "Bitte gib deinen Wohnort an!" },
		genderError: { id: "address.genderError", defaultMessage: "Bitte gib dein Geschlecht an!" },
		languageError: { id: "address.languageError", defaultMessage: "Bitte gib die Korrespondenzsprache an!" },
		audioLanguageError: { id: "address.audioLanguageError", defaultMessage: "Bitte gib die gewünschte Sprache der Analyse an!" },
		currencyError: { id: "address.currencyError", defaultMessage: "Bitte gib die gewünschte Währung an!" },
		countryError: { id: "address.countryError", defaultMessage: "Bitte gib dein Land an an!" },
		passwordError: { id: "address.passwordError", defaultMessage: "Das Passwort ist zu unsicher!" },
		passwordNotEqual: { id: "address.passwordNotEqual", defaultMessage: "Die Bestätigung muss mit dem Password übereinstimmen!" },
		fieldError_subject: { id: "customerSupport.fieldError_subject", defaultMessage: "Du musst einen Betreff angeben." },
		fieldError_question: { id: "customerSupport.fieldError_question", defaultMessage: "Bitte gib dein Anliegen an." }
	});

	if (fieldError === undefined) {
		return null;
	} else if (messages[fieldError]) {
		return (
			<div className="inlineError">
				<div>
					<div></div>
					<Icon />
					{intl.formatMessage(messages[fieldError])}
				</div>
			</div>
		);
	} else {
		return <div>Meldung «{fieldError}» nicht definiert...</div>;
	}
};

export default FieldError;
