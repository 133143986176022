import React, { Fragment, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { userConfirmEMailChange } from "../../../store/actions/index";
import { getRoute } from "../../../shared/routes";

const EMailAdresseBestaetigung = (props) => {
	const key = props.match.params.key;
	const language = useSelector((state) => state.app.language);

	const dispatch = useDispatch();
	const confirmEMailChange = useCallback((key, redirectOnSuccess, redirectOnError) => dispatch(userConfirmEMailChange(key, redirectOnSuccess, redirectOnError)), [dispatch]);

	useEffect(() => {
		confirmEMailChange(key, getRoute("thanks", language), getRoute("error", language));
	}, [confirmEMailChange, key, language]);

	return (
		<Fragment>
			<h1>
				<FormattedMessage id="menu.changeEMail" />
			</h1>
		</Fragment>
	);
};

export default EMailAdresseBestaetigung;
