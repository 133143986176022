import React, { useState, useRef, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import ReactPasswordStrength from "react-password-strength";

import { appSetError, authSetNewForgottenPassword, appClearFieldError } from "../../store/actions/index";

import Button from "../../ui/Button";
import FieldError from "../../ui/FieldError";

const labels = defineMessages({
	passwordWeak: { id: "address.passwordWeak", defaultMessage: "sehr unsicher" },
	passwordWeakBetter: { id: "address.passwordWeakBetter", defaultMessage: "unsicher" },
	passwordOk: { id: "address.passwordOk", defaultMessage: "gut" },
	passwordGood: { id: "address.passwordGood", defaultMessage: "stark" },
	passwordStrong: { id: "address.passwordStrong", defaultMessage: "sehr stark" },
	passwordToShort: { id: "address.passwordToShort", defaultMessage: "zu kurz" }
});

const SetNewPassword = (props) => {
	const key = props.match.params.key;

	const [passwords, setPasswords] = useState({ password: "", password2: "" });
	const [passwordValid, setPasswordValid] = useState(false);
	const history = useHistory();
	const inputRef = useRef();
	const dispatch = useDispatch();
	const setError = (error, FieldErrors, clearErrors) => dispatch(appSetError(error, FieldErrors, clearErrors));
	const dipatchClearFieldError = (field) => dispatch(appClearFieldError(field));
	const setNewForgottenPassword = (password, key) => dispatch(authSetNewForgottenPassword(password, key));
	const intl = useIntl();

	useEffect(() => {
		inputRef.current.reactPasswordStrengthInput.focus();
	}, []);

	const onCancel = () => {
		history.push("/");
	};

	const onSubmit = (event) => {
		event.preventDefault();
		let formIsValid = true;
		const fieldErrors = {};
		if (!passwordValid) {
			formIsValid = false;
			fieldErrors["password"] = "passwordError";
		} else if (passwords.password !== passwords.password2) {
			formIsValid = false;
			fieldErrors["password2"] = "passwordConfirmationInvalid";
		}
		if (formIsValid) {
			setNewForgottenPassword(passwords.password, key);
		} else {
			setError(null, fieldErrors, true);
		}
		window.scrollTo(0, 0);
	};

	const changeField = (event) => {
		const fieldName = event.target.name;
		const fieldValue = event.target.value;
		dipatchClearFieldError(fieldName);
		setPasswords((prev) => ({ ...prev, [fieldName]: fieldValue }));
	};

	const changePassword = (state) => {
		dipatchClearFieldError("password");
		setPasswords((prev) => ({ ...prev, password: state.password }));
		setPasswordValid(state.isValid);
	};

	return (
		<Fragment>
			<h1>
				<FormattedMessage id="passwordForgotten.newPassword" defaultMessage="Neues Passwort" />
			</h1>
			<form onSubmit={onSubmit} className="spaceBefore">
				<label className="above">
					<FormattedMessage id="passwordForgotten.newPasswordLabel" defaultMessage="Neues Passwort:" />
					<ReactPasswordStrength
						ref={inputRef}
						minLength={8}
						minScore={3}
						scoreWords={[intl.formatMessage(labels.passwordWeak), intl.formatMessage(labels.passwordWeakBetter), intl.formatMessage(labels.passwordOk), intl.formatMessage(labels.passwordGood), intl.formatMessage(labels.passwordStrong)]}
						tooShortWord={intl.formatMessage(labels.passwordToShort)}
						changeCallback={changePassword}
					/>
					<FieldError field="password" />
				</label>
				<label className="above">
					<FormattedMessage id="passwordForgotten.newPasswordConfirmation" defaultMessage="Neues Passwort (Bestätigung):" />
					<input type="password" value={passwords.password2} name="password2" onChange={(event) => changeField(event)} />
					<FieldError field="password2" />
				</label>
				<div className="bottomButtons spaceBefore">
					<div>
						<Button action={onCancel}>
							<FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
						</Button>
					</div>
					<div>
						<Button btnClass="default" type="submit">
							<FormattedMessage id="app.ok" defaultMessage="OK" />
						</Button>
					</div>
				</div>
			</form>
		</Fragment>
	);
};

export default SetNewPassword;
