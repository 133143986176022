import { useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";

import { appSetRedirect, restartSession } from "../../store/actions/index";

const PageChange = ({ history }) => {
	const dispatch = useDispatch();
	const clearRedirect = useCallback(() => dispatch(appSetRedirect(null)), [dispatch]);
	const dispatchRestartSession = useCallback(() => dispatch(restartSession()), [dispatch]);

	useEffect(() => {
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0);
			clearRedirect();
			dispatchRestartSession();
		});
		return () => {
			unlisten();
		};
	}, [history, clearRedirect, dispatchRestartSession]);

	return null;
};

export default withRouter(PageChange);
