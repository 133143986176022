import React, { useState, useRef, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { authPasswordForgotten } from "../../store/actions/index";

import Button from "../../ui/Button";
import { getRoute } from "../../shared/routes";

const PasswordForgotten = () => {
	const [eMail, set_eMail] = useState("");
	const [formSubmitEnabled, setFormSubmitEnabled] = useState(false);
	const inputRef = useRef();
	const dispatch = useDispatch();
	const dispatchPasswordForgotten = (eMail, mailSubject, mailTextBeforeLink, mailTextAfterLink, link) => dispatch(authPasswordForgotten(eMail, mailSubject, mailTextBeforeLink, mailTextAfterLink, link));
	const language = useSelector((state) => state.app.language);
	const link = getRoute("newPassword", language);

	const intl = useIntl();
	const messages = defineMessages({
		mailSubject: { id: "passwordForgotten.mailSubject", defaultMessage: "Passwort vergessen" },
		mailTextBeforeLink: {
			id: "passwordForgotten.mailTextBeforeLink",
			defaultMessage: "Guten Tag\nDu hast dein Passwort für den Login ins Kundenportal von Rico Brunner vergessen.\n\nDamit du ein neues Passwort setzen kannst bitten wir dich, dieses innerhalb einer Stunde mit dem Anklicken des untenstehenden Links zu bestätigen."
		},
		mailTextAfterLink: { id: "passwordForgotten.mailTextAfterLink", defaultMessage: "Wir wünschen dir einen ganz schönen Tag.\n\nHerzliche Grüsse\nTeam Rico Brunner" }
	});

	useEffect(() => {
		inputRef.current.focus();
	}, []);

	const onSubmit = (event) => {
		event.preventDefault();
		dispatchPasswordForgotten(eMail, intl.formatMessage(messages.mailSubject), intl.formatMessage(messages.mailTextBeforeLink), intl.formatMessage(messages.mailTextAfterLink), link);
	};

	const changeEMail = (event) => {
		set_eMail(event.target.value);
		const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
		setFormSubmitEnabled(pattern.test(event.target.value));
	};

	return (
		<Fragment>
			<h1>
				<FormattedMessage id="passwordForgotten.passwordForgotten" defaultMessage="Passwort vergessen" />
			</h1>
			<form onSubmit={onSubmit} className="spaceBefore">
				<label className="above">
					<FormattedMessage id="passwordForgotten.registeredEMail" defaultMessage="Bitte gib deine bei uns registrierte E-Mail Adresse an:" />
					<input type="text" ref={inputRef} value={eMail} onChange={changeEMail} />
				</label>
				<div className="bottomButtons spaceBefore">
					<div>
						<Button home btnClass="default" type="submit" disabled={!formSubmitEnabled}>
							<FormattedMessage id="app.next" defaultMessage="Weiter" />
						</Button>
					</div>
				</div>
			</form>
		</Fragment>
	);
};

export default PasswordForgotten;
